import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

interface RecommendationsProps {
  index: number;
  recommendations: any[];
}

export function RecommendationsTooltip(props: Readonly<RecommendationsProps>) {
  return (
    <dd data-tooltip-id={"-recommendations-tooltip" + props?.index}>
      <span className="flex items-center">
        Recommendations: {(props?.recommendations || []).length}
        {(props?.recommendations || []).length > 0 && (
          <InformationCircleIcon aria-hidden="true" className="w-4 h-4 ml-1" />
        )}
      </span>
      {(props?.recommendations || []).length > 0 && (
        <Tooltip
          id={"-recommendations-tooltip" + props?.index}
          arrowColor="transparent"
          place="bottom"
        >
          <div style={{}}>
            {(props?.recommendations || []).map((recommendation: any) => (
              <>
                <span>Summary: {recommendation?.summary}</span> <br />
                <span>Severity: {recommendation?.severity}</span> <br />
                <hr />
              </>
            ))}
          </div>
        </Tooltip>
      )}
    </dd>
  );
}

interface ErrorsProps {
  index: number;
  errors: Array<{ code: string; message: string }>;
}

export function ErrorsTooltip(props: Readonly<ErrorsProps>) {
  return (
    <>
      {(props?.errors || []).length > 0 && (
        <dd data-tooltip-id={"-errors-tooltip" + props?.index}>
          <span className="flex items-center">
            Errors: {(props?.errors || []).length}
            <InformationCircleIcon
              aria-hidden="true"
              className="w-4 h-4 ml-1"
            />
          </span>
          {(props?.errors || []).length > 0 && (
            <Tooltip
              id={"-errors-tooltip" + props?.index}
              arrowColor="transparent"
              place="bottom"
            >
              <div
                style={{
                  maxHeight: "500px",
                  maxWidth: "500px",
                  overflow: "auto",
                }}
              >
                {(props?.errors || []).map((error: any) => (
                  <>
                    <span>Code: {error?.code}</span> <br />
                    <span style={{ whiteSpace: "pre-line" }}>
                      Message: {error?.message}
                    </span>{" "}
                    <br />
                    <hr />
                  </>
                ))}
              </div>
            </Tooltip>
          )}
        </dd>
      )}
    </>
  );
}