import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../reports/modal/confirmationModal";
import OpportunityService from "../../../app/service/opportunityService";
import ResourceDetialsTable from "../details/resourceDetailsTable";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  resource: OpportunityPlanResourceItem | undefined;
  date: string;
  isCreate?: boolean;
}

const deleteResourceModal = {
  title: "Delete Resource",
  message: "Are you sure you want to delete the Resource",
};

export default function ResourceDetialModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [resourceName, setResourceName] = useState<string>("");

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      const listNames = props?.resource?.cspResourceId?.split("/") ?? [];
      if (listNames.length > 0) {
        setResourceName(listNames[listNames.length - 1]);
      }
    }
  }, [props.show]);

  useEffect(() => {
    setDateRange(initDate());
  }, [props?.date]);

  const initDate = () => {
    return props?.date || commonService.getDate();
  };

  const [dateRange, setDateRange] = useState<string>(initDate());

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const [showDeleteResourceModal, setShowDeleteResourceModal] =
    useState<boolean>(false);
  const [loadingDeleteResource, setLoadingDeleteResource] =
    useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<string>("");

  const openDeleteResourceModal = (e: any) => {
    e?.preventDefault();
    setDeleteMessage(`${deleteResourceModal.message}: ${resourceName} ?`);
    setShowDeleteResourceModal(true);
  };

  const closeDeleteResourceModal = () => {
    setShowDeleteResourceModal(false);
  };

  const onDeleteResource = () => {
    const id = props?.resource?.id;
    if (id) {
      setLoadingDeleteResource(true);
      OpportunityService.deleteOpportunityPlanResource(id)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeleteResource(false);
          if (response?.data?.isSuccess) {
            closeDeleteResourceModal();
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoadingDeleteResource(false);
        });
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteResourceModal}
        title={deleteResourceModal.title}
        message={deleteMessage}
        loading={loadingDeleteResource}
        closeModal={() => closeDeleteResourceModal()}
        save={() => onDeleteResource()}
      />

      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                Resource Details: {resourceName}
              </Dialog.Title>
              <div className="mt-2">
                <ResourceDetialsTable
                  resource={props?.resource}
                  date={dateRange}
                  isCreate={props?.isCreate}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            {!props?.isCreate && (
              <button
                type="button"
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-1"
                )}
                onClick={(e) => openDeleteResourceModal(e)}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Close
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
