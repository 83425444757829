import { Tooltip } from "react-tooltip";

interface Props {
  id: string;
  tooltipText: string;
}

export default function TooltipText(props: Readonly<Props>) {
  return (
      <Tooltip
        id={props?.id}
        arrowColor="transparent"
        place="top"
      >
        <div style={{}}>
          <span>{props?.tooltipText}</span>
        </div>
      </Tooltip>
  );
}
