import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import DelegatedReaderAccessDocumentModal from "./delegatedReaderAccessDocumentModal";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import ProposalService from "../../../app/service/proposalService";
import { useNavigate } from "react-router-dom";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  mode: string;
  proposal: AccountSalesProposalItem | undefined;
}

const errorState: any = {
  comment: { hasError: false, required: true },
};

export default function ProposalModal(props: Readonly<Props>) {
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );
  const [showDelegatedReaderAccessModal, setShowDelegatedReaderAccessModal] =
    useState<boolean>(false);
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );
  const [comment, setComment] = useState<string>("");

  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(true);

  const title =
    props?.mode === commonService.accountSalesProposalStatus.Approved
      ? "Accept Proposal"
      : "Reject Proposal";
  const btnText =
    props?.mode === commonService.accountSalesProposalStatus.Approved
      ? "Accept"
      : "Reject";
  const message = props?.proposal?.isPreProposal
    ? "No we just need reader access to your environment so we can perform an analysis and provide a proposal"
    : "A PM will contact to schedule FinOps call and next steps";

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      setComment("");
      getAllSubscriptions();
    }
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props?.closeModal(refresh);
  };

  const action = () => {
    if (props?.mode === commonService.accountSalesProposalStatus.Approved) {
      updateStatus();
    } else {
      onCheckError();
      checkError();
      if (comment) {
        updateStatus();
      }
    }
  };

  const updateStatus = () => {
    if (
      (props?.mode === commonService.accountSalesProposalStatus.Approved ||
        (props?.mode === commonService.accountSalesProposalStatus.Rejected &&
          comment)) &&
      !loading
    ) {
      const body: UpdateProposalStatusRequest = {
        id: props?.proposal?.id ?? "",
        status: props.mode,
        decisionComments:
          props?.mode === commonService.accountSalesProposalStatus.Approved
            ? "Accept"
            : comment,
      };
      setLoading(true);
      ProposalService.updateStatus(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            if (
              props?.mode === commonService.accountSalesProposalStatus.Approved
            ) {
              openDelegatedReaderAccessModal();
            } else {
              closeModal(true);
              navigate("/newAccountProsessing");
            }
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onCheckError = () => {
    onInputChange(comment);
  };

  const onInputChange = (value: string) => {
    error.comment.hasError = !value;
    setComment(value);
  };

  const checkError = () => {
    setHasError(!comment);
  };

  const openDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(true);
  };

  const closeDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(false);
    if (props?.mode === commonService.accountSalesProposalStatus.Approved) {
      navigate("/");
    } else {
      window.location.reload();
    }
  };

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  return (
    <>
      <DelegatedReaderAccessDocumentModal
        show={showDelegatedReaderAccessModal}
        closeModal={() => closeDelegatedReaderAccessModal()}
        subscriptionList={subscriptionList}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="mt-2">
                <div className="mt-2">
                  {props?.mode ===
                  commonService.accountSalesProposalStatus.Approved ? (
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      {message}
                    </p>
                  ) : (
                    <form className="space-y-6">
                        <div>
                          <label
                            htmlFor="comment"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Comment
                          </label>
                          <div className="mt-2">
                            <textarea
                              rows={4}
                              name="comment"
                              id="comment"
                              className={commonService.classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                hasError && error?.comment?.hasError
                                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                  : ""
                              )}
                              required
                              value={comment}
                              onChange={(e) => onInputChange(e?.target?.value)}
                            />
                          </div>
                          {error?.comment?.hasError && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="order-error"
                            >
                              Comment is required.
                            </p>
                          )}
                        </div>
                      </form>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="submit"
              disabled={loading}
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                loading ? "disabled:opacity-75" : ""
              )}
              onClick={() => action()}
            >
              <Spinner show={loading} />
              {btnText}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
