import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import OpportunityService from "../../../app/service/opportunityService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";
import { StepForm } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal?: any;
  plan: OpportunityPlanItem | undefined;
  isEdit?: boolean;
  step?: OpportunityPlanStepItem;
  refreshProject?: any;
  fromResourceModal?: boolean;
}

export default function PlanStepModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const cancelButtonRef = useRef(null);

  const editStepName = props.step?.name;

  const [errorCheck, setErrorCheck] = useState<boolean>(false);
  const [onErrorCheck, setOnErrorCheck] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.show);
    init();
    checkError();
  }, [props.show]);

  const onCheckError = () => {
    setOnErrorCheck(true);
    setTimeout(() => {
      setOnErrorCheck(false);
    }, 10);
  }

  const init = () => {
    setStep(initStep());
  };

  const initStep = (): OpportunityPlanStepItem => {
    if (props.isEdit) {
      return JSON.parse(JSON.stringify(props.step ?? {})) || {};
    }
    return commonService.stepItem();
  };

  const [step, setStep] = useState<OpportunityPlanStepItem | any>(initStep());

  useEffect(() => {
    checkError();
  }, [step]);

  const checkError = () => {
    setErrorCheck(true);
    setTimeout(() => {
      setErrorCheck(false);
    }, 10);
  }

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const createStep = () => {
    onCheckError();
    checkError();
    if (!hasError) {
      const body: CreateOrUpdateOpportunityPlanStepRequest = {
        accountId: commonService.getAccountId(),
        projectId: props.plan?.projectId ?? "",
        opportunityPlanId: props.plan?.id ?? "",
        name: step.name,
        description: step.description,
      };
      if (props?.isEdit) {
        body.id = step?.id;
      }
      setLoading(true);
      if (props?.isEdit) {
        OpportunityService.updateOpportunityPlanStep(body)
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      } else {
        OpportunityService.createOpportunityPlanStep(body)
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              closeModal(true);
              if(props?.fromResourceModal) {
                const data = {
                  project: response?.data?.result?.projectId || "",
                  plan: response?.data?.result?.opportunityPlanId || "",
                  step: response?.data?.result?.id || ""
                }
                props?.refreshProject(data);
              }
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      }
    }
  };

  const onInputChange = (stepModel: OpportunityPlanStepItem) => setStep(stepModel);

  const onCheckingError = (isError: boolean) => setHasError(isError);
  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {props?.isEdit
                ? `Edit Plan Step:  ${editStepName}`
                : "Add Plan Step"}
            </Dialog.Title>
            <div className="mt-2">
              <StepForm step={step} show={props?.show} onInputChange={onInputChange} onCheckError={onCheckingError}
              checkingError={errorCheck} onCheckingError={onErrorCheck} />
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createStep()}
          >
            <Spinner show={loading} />
            {props?.isEdit ? "Update" : "Create"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
