import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import OpportunityService from "../../../app/service/opportunityService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../reports/modal/confirmationModal";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  plan: OpportunityPlanItem | undefined;
  isEdit?: boolean;
  contactItem: OpportunityPlanContactsItem | undefined;
  refreshDetails?: any;
}

const errorState: any = {
  externalUserName: { hasError: false, required: true },
  externalUserEmail: { hasError: false, required: true },
  externalUserPhone: { hasError: false, required: true },
  role: { hasError: false, required: true },
};

const projectManager: string = "Project Manager";
const technicalPOC: string = "Technical POC";
const financePOC: string = "Finance POC";

const roleList: string[] = [projectManager, technicalPOC, financePOC];

const deleteContactModal = {
  title: "Delete Contact",
  message: "Are you sure you want to delete the Contact",
};

export default function ContactModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const [contactList, setContactList] = useState<User[]>([]);

  const cancelButtonRef = useRef(null);

  const contactTitle = props?.isEdit ? `Edit Contact:  ${props.contactItem?.externalUserName}` : "Add Contact";

  const contactTypeList = ["Internal Contact", "External Contact"];
  const [contactType, setContactType] = useState<string>(contactTypeList[1]);
  const [selectdContact, setSelectdContact] = useState<string>("");

  const [isExternal, setIsExternal] = useState<boolean>(true);

  useEffect(() => {
    setOpen(props.show);
    init();
    checkError();
    setSelectdContact("");
    setContactType(contactTypeList[1]);
    setContactList(commonService.users?.allUsers || []);
    setIsExternal(!!props?.contactItem?.externalUserName);
  }, [props.show]);

  const init = () => {
    setContact(initContacts());
  };

  const initContacts = (): OpportunityPlanContactsItem => {
    if (props.isEdit) {
      const currentContact = JSON.parse(
        JSON.stringify(props.contactItem ?? {})
      );
      return currentContact || {};
    }
    return {
      id: "",
      accountId: "",
      projectId: "",
      opportunityPlanId: "",
      userId: "",
      externalUserEmail: "",
      externalUserName: "",
      externalUserPhone: "",
      role: roleList[0],
      isApprover: false,
      isActive: false,
    };
  };

  const [contact, setContact] = useState<OpportunityPlanContactsItem | any>(
    initContacts()
  );

  useEffect(() => {
    checkError();
  }, [contact, contactType]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const createContact = () => {
    const userData = commonService.userDetail();
    const body: CreateOrUpdateOpportunityPlanContactsRequest = {
      accountId: "",
      projectId: props.plan?.projectId ?? "",
      opportunityPlanId: props?.plan?.id ?? "",
      userId: "",
      externalUserEmail:
        contactType === contactTypeList[0] ? "" : contact.externalUserEmail,
      externalUserName:
        contactType === contactTypeList[0] ? "" : contact.externalUserName,
      externalUserPhone:
        contactType === contactTypeList[0] ? "" : contact.externalUserPhone,
      role: contact.role || "",
      isApprover: contact.isApprover,
      isActive: true,
    };
    if (props?.isEdit) {
      body.id = contact?.id;
      body.userId = contact.userId;
      body.accountId = contact.accountId;
    } else {
      if (contactType === contactTypeList[0]) {
        body.userId = selectdContact;
      } else {
        body.userId = userData.id;
      }
      body.accountId = commonService.getAccountId();
    }
    setLoading(true);
    if (props?.isEdit) {
      OpportunityService.updateOpportunityPlanContacts(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoading(false);
        });
    } else {
      OpportunityService.createOpportunityPlanContacts(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoading(false);
        });
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    if (fieldName === "externalUserEmail") {
      field.hasError = !value || !commonService.isValidEmail(value);
    } else {
      field.hasError = !value;
    }
    const model = JSON.parse(JSON.stringify(contact));
    model[fieldName] = value;
    setContact(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (
        errorState[key]?.required &&
        (!contact[key] ||
          (key === "externalUserEmail" &&
            !commonService.isValidEmail(contact[key])))
      ) {
        isError = true;
      }
    }
    if (contactType === contactTypeList[0]) {
      setHasError(false);
    } else {
      setHasError(isError);
    }
  };

  const onCheckboxChange = (checked: boolean) => {
    const model = JSON.parse(JSON.stringify(contact));
    model.isApprover = checked;
    setContact(model);
  };

  const onChangeType = (type: string) => {
    setContactType(type);
    contact.externalUserName = "";
    contact.externalUserEmail = "";
    contact.externalUserPhone = "";
    setSelectdContact("");
    setError(JSON.parse(JSON.stringify(errorState)));
    if (type === contactTypeList[0]) {
      const id = contactList[0]?.id;
      setSelectdContact(id);
      onChangeContact(id);
      setHasError(false);
    }
    checkError();
  };

  const onChangeContact = (val: string) => {
    setSelectdContact(val);
    const currentContact = contactList.find((x) => x.id == val);
    if (currentContact) {
      contact.externalUserName = currentContact.username || "";
      contact.externalUserEmail = currentContact.email || "";
      contact.externalUserPhone = currentContact.phone || "";
    }
    checkError();
  };

  const [showDeleteContactModal, setShowDeleteContactModal] =
    useState<boolean>(false);
  const [loadingDeleteContact, setLoadingDeleteContact] =
    useState<boolean>(false);
  const [selectedContactForDelete, setSelectedContactForDelete] =
    useState<string>();
  const [deleteMessage, setDeleteMessage] = useState<string>("");

  const openDeleteContactModal = (
    e: any,
    contact: OpportunityPlanContactsItem
  ) => {
    e?.preventDefault();
    setDeleteMessage(
      `${deleteContactModal.message}: ${
        contact.externalUserName || contact?.user?.username
      } ?`
    );
    setSelectedContactForDelete(contact.id);
    setShowDeleteContactModal(true);
  };

  const closeDeleteContactModal = () => {
    setShowDeleteContactModal(false);
  };

  const onDeleteContact = () => {
    if (selectedContactForDelete) {
      setLoadingDeleteContact(true);
      OpportunityService.deleteOpportunityPlanContacts(selectedContactForDelete)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeleteContact(false);
          if (response?.data?.isSuccess) {
            closeDeleteContactModal();
            props?.refreshDetails(true);
            closeModal();
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoadingDeleteContact(false);
        });
    }
  };

  const isDisabled = () => {
    return (
      (hasError &&
        ((!props?.isEdit && contactType === contactTypeList[1]) ||
          (props?.isEdit && isExternal))) ||
      loading
    );
  };

  const isDisabledInput = () => {
    return (
      (!props?.isEdit && contactType === contactTypeList[0]) ||
      (props.isEdit && !isExternal)
    );
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteContactModal}
        title={deleteContactModal.title}
        message={deleteMessage}
        loading={loadingDeleteContact}
        closeModal={() => closeDeleteContactModal()}
        save={() => onDeleteContact()}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 mb-10 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {contactTitle}
              </Dialog.Title>
              <div className="mt-2">
                <div>
                  <form className="space-y-6">
                    {props.isEdit && !isExternal && (
                      <div className="flex flex-col items-center">
                        <div className="col-span-full flex items-center gap-x-8">
                          {contact.user?.pictureUrl ? (
                            <img
                              src={contact.user?.pictureUrl}
                              alt=""
                              className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                            />
                          ) : (
                            <a
                              href="#"
                              className="relative bg-indigo-600 text-white z-30 inline-block h-24 w-24 rounded-full text-center text-[4rem]"
                            >
                              {contact.user?.username
                                ?.toString()[0]
                                ?.toUpperCase() || ""}
                            </a>
                          )}
                        </div>
                        <div className="text-center">
                          {contact?.user?.username}
                          <br />
                          {contact?.user?.email}
                        </div>
                      </div>
                    )}
                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      {!props.isEdit && (
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="contactType"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Contact Type
                          </label>
                          <div className="mt-1 mb-1">
                            <select
                              id="contactType"
                              name="contactType"
                              className={commonService.classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              )}
                              value={contactType}
                              required
                              onChange={(e) => onChangeType(e?.target?.value)}
                            >
                              {contactTypeList.map((c: string) => (
                                <option value={c} key={c}>
                                  {c}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {contactType === contactTypeList[0] && (
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="selectdContact"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Choose Contact
                          </label>
                          <div className="mt-1 mb-1">
                            <select
                              id="selectdContact"
                              name="selectdContact"
                              className={commonService.classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              )}
                              value={selectdContact}
                              required
                              onChange={(e) =>
                                onChangeContact(e?.target?.value)
                              }
                            >
                              {contactList.map((c: any) => (
                                <option value={c.id} key={c.id}>
                                  {c.username}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {!(props.isEdit && !isExternal) && (
                        <>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="externalUserName"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Name
                            </label>
                            <div className="mt-2">
                              <input
                                id="externalUserName"
                                name="externalUserName"
                                type="text"
                                required
                                value={contact.externalUserName}
                                onChange={(e) =>
                                  onInputChange(
                                    "externalUserName",
                                    e?.target?.value
                                  )
                                }
                                className={commonService.classNames(
                                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                  !isDisabledInput() &&
                                    commonService.hasFieldError(error, 'externalUserName')
                                    ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                    : ""
                                )}
                                disabled={isDisabledInput()}
                              />
                            </div>
                            {!isDisabledInput() &&
                              commonService.hasFieldError(error, 'externalUserName') && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="order-error"
                                >
                                  Name is required.
                                </p>
                              )}
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="externalUserEmail"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Email
                            </label>
                            <div className="mt-2">
                              <input
                                id="externalUserEmail"
                                name="externalUserEmail"
                                type="email"
                                required
                                value={contact.externalUserEmail}
                                onChange={(e) =>
                                  onInputChange(
                                    "externalUserEmail",
                                    e?.target?.value
                                  )
                                }
                                className={commonService.classNames(
                                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                  !isDisabledInput() &&
                                  commonService.hasFieldError(error, 'externalUserEmail')
                                    ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                    : ""
                                )}
                                disabled={isDisabledInput()}
                              />
                            </div>
                            {!isDisabledInput() &&
                              commonService.hasFieldError(error, 'externalUserEmail') && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="order-error"
                                >
                                  {!contact.externalUserEmail
                                    ? "Email is required."
                                    : "Invalid Email"}
                                </p>
                              )}
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="externalUserPhone"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Phone
                            </label>
                            <div className="mt-2">
                              <input
                                id="externalUserPhone"
                                name="externalUserPhone"
                                type="number"
                                required
                                value={contact.externalUserPhone}
                                onChange={(e) =>
                                  onInputChange(
                                    "externalUserPhone",
                                    e?.target?.value
                                  )
                                }
                                className={commonService.classNames(
                                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                  !isDisabledInput() &&
                                  commonService.hasFieldError(error, 'externalUserPhone')
                                    ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                    : ""
                                )}
                                disabled={isDisabledInput()}
                              />
                            </div>
                            {!isDisabledInput() &&
                              commonService.hasFieldError(error, 'externalUserPhone') && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="order-error"
                                >
                                  Phone is required.
                                </p>
                              )}
                          </div>
                        </>
                      )}

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="role"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Role
                        </label>
                        <div className="mt-1 mb-1">
                          <select
                            id="role"
                            name="role"
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              commonService.hasFieldError(error, 'role')
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                            value={contact.role}
                            required
                            onChange={(e) =>
                              onInputChange("role", e?.target?.value)
                            }
                          >
                            {roleList.map((role: string) => (
                              <option value={role} key={role}>
                                {role}
                              </option>
                            ))}
                          </select>
                        </div>
                        {commonService.hasFieldError(error, 'role') && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            Role is required.
                          </p>
                        )}
                      </div>

                      <div className="sm:col-span-6 flex items-center">
                        <input
                          id="isApprover"
                          name="isApprover"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          value="true"
                          checked={contact.isApprover}
                          onChange={(e) => onCheckboxChange(e.target.checked)}
                        />
                        <div className="ml-2">
                          <label
                            htmlFor="same-as-shipping"
                            className="text-sm font-medium text-gray-900"
                          >
                            Approver
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              props?.isEdit ? "sm:grid-cols-3" : "sm:grid-cols-2"
            )}
          >
            <button
              type="submit"
              disabled={isDisabled()}
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                isDisabled() ? "disabled:opacity-75" : "",
                props.isEdit ? "sm:col-start-3" : "sm:col-start-2"
              )}
              onClick={() => createContact()}
            >
              <Spinner show={loading} />
              {props?.isEdit ? "Update" : "Create"}
            </button>
            {props.isEdit && (
              <button
                type="button"
                disabled={loading}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={(e) => openDeleteContactModal(e, contact)}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
