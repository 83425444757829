import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import * as XLSX from "xlsx";
import { ModalWrapper } from "../../common/modalWrapper";
import { StepView } from "../../../app/common/commonControls";

interface Props {
  show: boolean;
  closeModal?: any;
  allResources: OpportunityPlanResourceItem[];
}

const defaultSteps = [
  {
    id: 1,
    name: "Select Resources",
    href: "#",
    status: commonService.stepStatus.CURRENT,
  },
  {
    id: 2,
    name: "Select Template",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
  {
    id: 3,
    name: "Confirmation",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
];

export default function ResourceExportModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const [steps, setSteps] = useState<any[]>([]);
  const [selectedStep, setSelectedStep] = useState<any>(defaultSteps[0]);

  const [allResources, setAllResources] = useState<
    OpportunityPlanResourceItem[]
  >([]);

  const [selectedResource, setSelectedResource] = useState<
    OpportunityPlanResourceItem[]
  >([]);

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const cancelButtonRef = useRef(null);

  const modalTitle = "Export Resources";

  const [isPageOneValid, setIsPageOneValid] = useState<boolean>(false);
  const [isPageTwoValid, setIsPageTwoValid] = useState<boolean>(false);

  const [confirmTable, setConfirmTable] = useState<any[]>([]);
  const [templates, setTemplates] = useState<OpportunityPlanResourceTaskItem[]>(
    []
  );

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
      initStep();
    }
  }, [props.show]);

  const initStep = () => {
    setSteps(JSON.parse(JSON.stringify(defaultSteps)));
  };

  const init = () => {
    const currentResources = JSON.parse(JSON.stringify(props.allResources));
    (currentResources || []).forEach((x: OpportunityPlanResourceItem) => {
      (x.opportunityPlanResourceTask || []).forEach((o: any) => {
        o["select"] = false;
      });
    });
    setAllResources(currentResources);
  };

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedResource.length > 0 &&
      selectedResource.length < allResources.length;
    setChecked(
      selectedResource.length === allResources.length &&
        allResources?.length > 0
    );
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
    setIsPageOneValid(selectedResource?.length > 0);
  }, [selectedResource]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onStepSelect = (step: any) => {
    if (step?.id === 2) {
      if (selectedResource?.length === 0) {
        return;
      }
      filterTemplates();
    }
    if (step?.id === 3) {
      if (!isPageOneValid || !isPageTwoValid) {
        return;
      }
      filterConfirmTable();
    }
    setSelectedStep(step);
    steps.forEach((x) => {
      if (x.id === step.id) {
        x.status = commonService.stepStatus.CURRENT;
      } else if (x.id < step.id) {
        x.status = commonService.stepStatus.COMPLETE;
      } else {
        x.status = commonService.stepStatus.UPCOMING;
      }
    });
  };

  const toggleAll = () => {
    setSelectedResource(checked || indeterminate ? [] : allResources);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const onNext = () => {
    if (selectedStep?.id === 1) {
      if (!isPageOneValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    } else if (selectedStep?.id === 2) {
      if (!isPageTwoValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    }
  };

  const goBack = () => {
    onStepSelect(steps[selectedStep.id - 2]);
  };

  const exportResource = () => {
    if (isPageOneValid && isPageTwoValid && !loading) {
      downloadExcel();
    }
  };

  const [state, setState] = useState<string>();

  const onSelectTemplate = (
    checked: boolean,
    task: OpportunityPlanResourceTaskItem
  ) => {
    task.select = checked;
    const newState = Math.random()?.toString();
    setState(state ? "" : newState);
    if (checked) {
      setIsPageTwoValid(true);
    } else {
      checkPageTwoValid(templates);
    }
  };

  const checkPageTwoValid = (
    currentTemplates: OpportunityPlanResourceTaskItem[]
  ) => {
    const isValid = (currentTemplates || []).some((o) => o.select);
    setIsPageTwoValid(!!isValid);
  };

  const filterConfirmTable = () => {
    const table: any[] = [];
    templates.forEach((x) => {
      if (x.select) {
        selectedResource?.forEach((s) => {
          const task = (s.opportunityPlanResourceTask || []).find(
            (o) =>
              o.opportunityPlanResourceTaskTemplateId ===
              x.opportunityPlanResourceTaskTemplateId
          );
          if (task) {
            const data = {
              "Resource Name":
                s?.dailyData?.data[0]?.resourceName ||
                s?.cspResourceId?.split("/")[8],
              "Template Name": task.opportunityPlanResourceTaskTemplate.name,
              CSP: task.opportunityPlanResourceTaskTemplate.csp,
              "Template Description":
                task.opportunityPlanResourceTaskTemplate.description,
              Hours: task.opportunityPlanResourceTaskTemplate.hours,
              Command: task.command,
              "Sequence Order": task.sequenceOrder,
              Value: task.value,
              note: task.note,
              "Completed On": task.completedOn
                ? new Date().toLocaleString()
                : "",
              "Completed By": task.completedBy,
            };
            table.push(data);
          }
        });
      }
    });
    setConfirmTable(table);
  };

  const filterTemplates = () => {
    const selectedTemplates: OpportunityPlanResourceTaskItem[] = [];
    selectedResource?.forEach((x) => {
      (x.opportunityPlanResourceTask || []).forEach((o) => {
        const isExist = selectedTemplates.find(
          (s) =>
            s.opportunityPlanResourceTaskTemplateId ===
            o.opportunityPlanResourceTaskTemplateId
        );
        if (!isExist) {
          selectedTemplates.push(o);
        }
      });
    });
    setTemplates(selectedTemplates);
    checkPageTwoValid(selectedTemplates);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(confirmTable);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const today = new Date();
    const date =
      today.getFullYear() +
      "" +
      (today.getMonth() + 1) +
      "" +
      today.getDate() +
      "_";
    const time =
      today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
    const name = "Resources" + date + time + ".xlsx";
    XLSX.writeFile(workbook, name);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {modalTitle}
            </Dialog.Title>
            <div className="mt-2">
            <StepView steps={steps} onStepSelect={onStepSelect} />

              <div className="space-y-6 mt-8 px-4">
                {selectedStep?.id === 1 && (
                  <div>
                    <div className="h-full overflow-y-auto max-h-[50vh]">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6"
                            >
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Resource Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              CSP
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Subscription
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Resource Group
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {allResources.map((resource) => (
                            <tr
                              key={resource.id}
                              className={
                                selectedResource.includes(resource)
                                  ? "bg-gray-50"
                                  : undefined
                              }
                            >
                              <td className="relative px-7 sm:w-12 sm:px-6">
                                {selectedResource.includes(resource) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  value={resource.id}
                                  checked={selectedResource.includes(resource)}
                                  onChange={(e) =>
                                    setSelectedResource(
                                      e.target.checked
                                        ? [...selectedResource, resource]
                                        : selectedResource.filter(
                                            (p) => p !== resource
                                          )
                                    )
                                  }
                                />
                              </td>
                              <td
                                className={commonService.classNames(
                                  "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                  selectedResource.includes(resource)
                                    ? "text-indigo-600"
                                    : "text-gray-900"
                                )}
                              >
                                {resource?.dailyData?.data[0]?.resourceName ||
                                  resource?.cspResourceId?.split("/")[8]}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {
                                  resource?.opportunityPlanResourceTask[0]
                                    ?.opportunityPlanResourceTaskTemplate?.csp
                                }
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {resource?.cspResourceId?.split("/")[2]}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {resource?.cspResourceId?.split("/")[4]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {selectedStep?.id === 2 && (
                  <div>
                    <div className="my-2">
                      <div className="flex shrink-0 gap-x-4">
                        <div className="hidden sm:flex sm:flex-col sm:items-end w-60">
                          <fieldset className="w-60">
                            <div className="my-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                              {templates.map((task) => (
                                <div
                                  key={task.id}
                                  className="relative flex items-start"
                                >
                                  <div className="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                      htmlFor={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                      className="select-none font-medium text-gray-900"
                                    >
                                      {
                                        task.opportunityPlanResourceTaskTemplate
                                          ?.name
                                      }
                                    </label>
                                  </div>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      id={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                      name={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                      type="checkbox"
                                      checked={task.select ? true : false}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      onChange={(e) =>
                                        onSelectTemplate(
                                          e?.target?.checked,
                                          task
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedStep?.id === 3 && (
                  <div>
                    <div>
                      <div className="h-full overflow-y-auto max-h-[50vh]">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Resource Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Template Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Completed On
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Completed By
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {confirmTable.map((resource) => (
                              <tr
                                key={resource.id}
                                className={
                                  selectedResource.includes(resource)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {resource["Resource Name"]}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {resource["Template Name"]}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {resource["Completed On"]}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {resource["Completed By"]}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={commonService.classNames(
            "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
            selectedStep?.id === 1 ? "sm:grid-cols-2" : "sm:grid-cols-3"
          )}
        >
          {selectedStep?.id === 3 && (
            <button
              type="submit"
              disabled={loading || !isPageOneValid || !isPageTwoValid}
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3",
                loading || !isPageOneValid || !isPageTwoValid
                  ? "disabled:opacity-75"
                  : ""
              )}
              onClick={() => exportResource()}
            >
              <Spinner show={loading} />
              Export
            </button>
          )}
          {selectedStep?.id < steps.length && (
            <button
              type="button"
              disabled={
                (selectedStep?.id === 1 && !isPageOneValid) ||
                (selectedStep?.id === 2 && !isPageTwoValid)
              }
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                selectedStep?.id === 1 ? "sm:col-start-2" : "sm:col-start-3",
                (selectedStep?.id === 1 && !isPageOneValid) ||
                  (selectedStep?.id === 2 && !isPageTwoValid)
                  ? "disabled:opacity-75"
                  : ""
              )}
              onClick={() => onNext()}
            >
              Next
            </button>
          )}
          {selectedStep?.id > 1 && (
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
              onClick={() => goBack()}
            >
              Back
            </button>
          )}
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}