import { useState } from "react";
import InvoiceModal from "../initiatives/modal/invoiceModal"
import ConfirmationModal from "./modal/confirmationModal";
import ReportService from "../../app/service/reportService";
import commonService from "../../app/service/commonService";
import { toast } from "react-toastify";

interface Props {
    data: InvoiceStatusResult[],
    refreshDetails?: any;
}

const deleteInvoiceModal = {
    title: "Delete Invoice",
    message: "Are you sure you want to delete the Invoice",
};


export default function InvoiceStatusTable(props: Readonly<Props>) {

    const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
    const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState<boolean>(false);
    const [loadingDeleteInvoice, setLoadingDeleteInvoice] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<string>("");
    const [selectedInvoiceForDelete, setSelectedInvoiceForDelete] = useState<string>();

    const openInvoiceModal = () => {
        setShowInvoiceModal(true);
    }

    const closeInvoiceModal = () => {
        setShowInvoiceModal(false);
    };

    const openDeleteInvoiceModal = (e: any, invoice: InvoiceStatusResult) => {
        e?.preventDefault();
        setDeleteMessage(`${deleteInvoiceModal.message}: ${invoice.name} ?`);
        setSelectedInvoiceForDelete(invoice.id);
        setShowDeleteInvoiceModal(true);
    };

    const closeDeleteInvoiceModal = () => {
        setShowDeleteInvoiceModal(false);
        props.refreshDetails(true);
    };

    const onDeleteInvoice = () => {
        if (selectedInvoiceForDelete) {
            setLoadingDeleteInvoice(true);
            const body: string[] = [selectedInvoiceForDelete];
            ReportService.deleteInvoice(body)
            .then((response: any) => {
              commonService.showMessage(response?.data || {});
              setLoadingDeleteInvoice(false);
              if (response?.data?.isSuccess) {
                closeDeleteInvoiceModal();
              }
            })
            .catch((e: any) => {
              toast.error(`${e?.response?.data?.message}`);
              setLoadingDeleteInvoice(false);
            });
        }
    };

    const refreshDetails = (refresh?: boolean) => {
        if (refresh) {
            props.refreshDetails(true);
        }
    };

    return (
        <>
            <ConfirmationModal
                show={showDeleteInvoiceModal}
                title={deleteInvoiceModal.title}
                message={deleteMessage}
                loading={loadingDeleteInvoice}
                closeModal={() => closeDeleteInvoiceModal()}
                save={() => onDeleteInvoice()}
            />
            <InvoiceModal
                show={showInvoiceModal}
                closeModal={() => closeInvoiceModal()}
                refreshDetails={refreshDetails}
            />
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900">
                                        Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900">
                                        Year
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900">
                                        Month
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {props.data?.map((item) => (
                                    <tr key={item.id}>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">{item.name}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">{item.year}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">{item.month}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">{item.status}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                        <a
                                            href="#"
                                            className="text-indigo-600 hover:text-indigo-900 mr-3"
                                            onClick={(e) => openDeleteInvoiceModal(e, item)}
                                        >
                                            Delete
                                        </a>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td
                                        colSpan={5}
                                        className="relative whitespace-nowrap py-5 px-3 text-sm font-medium sm:pr-0"
                                    >
                                        <button
                                            type="button"
                                            className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={() => openInvoiceModal()}
                                        >
                                            Add Invoice
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}