import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  proposals: AccountSalesProposalItem[];
}

export default function ProposalDetailModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [proposal, setProposal] = useState<AccountSalesProposalItem | any>({});

  const [selectedProposal, setSelectedProposal] = useState<string>("");

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      if ((props?.proposals || [])?.length === 1) {
        setProposal(props?.proposals[0]);
      }
    }
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeProposal = (value: string) => {
    setSelectedProposal(value);
    setProposal(props.proposals.find((x) => x.id === value) || {});
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Proposal Details
            </Dialog.Title>
            <div className="mt-2">
              <div className="mt-6 border-t border-gray-100">
                {(props?.proposals || []).length > 0 ? (
                  <>
                    {(props?.proposals || []).length > 1 && (
                      <div className="mt-2">
                        <select
                          id="proposal"
                          name="proposal"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={selectedProposal}
                          required
                          onChange={(e) => onChangeProposal(e?.target?.value)}
                        >
                          <option value={""} key={"option1"}>
                            ---Choose Proposal---
                          </option>
                          {(props?.proposals || [])?.map((proposalItem) => (
                            <option
                              value={proposalItem.id}
                              key={proposalItem.id}
                            >
                              {proposalItem.updatedOn
                                ? new Date(
                                    proposalItem.updatedOn
                                  ).toLocaleString()
                                : proposalItem.id}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                        <div className="sm:grid-cols-1">
                          {!!proposal?.commitmentsCurrent && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Commitments Current:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.commitmentsCurrent}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.commitmentsSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Commitments Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.commitmentsSavings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.createdBy && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Created By:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.createdBy}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.createdOn && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Created On:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal.createdOn
                                  ? new Date(
                                      proposal.createdOn
                                    ).toLocaleString()
                                  : ""}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.currentInvoiceTotal && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Current Invoice Total:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.currentInvoiceTotal}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.customerId && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Customer ID:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.customerId}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.decisionComments && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Decision Comments:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.decisionComments}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.displaySavingsPercentage && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Display Savings Percentage:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.displaySavingsPercentage}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.governanceFindings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Governance Findings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.governanceFindings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.guaranteeSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Guarantee Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.guaranteeSavings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.id && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                ID:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.id}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.isActive && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Active:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {!!proposal?.isActive ? "Yes" : "No"}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.isPreProposal && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                PreProposal:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {!!proposal?.isPreProposal ? "Yes" : "No"}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.licensingCurrent && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Licensing Current:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.licensingCurrent}
                              </dd>
                            </div>
                          )}
                        </div>
                        <div className="sm:grid-cols-1">
                          {!!proposal?.licensingSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Licensing Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.licensingSavings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.notes && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Notes:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.notes}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.optimizationSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Optimization Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.optimizationSavings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.paymentTerm && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Payment Term:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.paymentTerm}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.plan && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Plan:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.plan}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.rateOptimizationSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Rate Optimization Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.rateOptimizationSavings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.resourceCount && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Resource Count:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.resourceCount}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.securityFindings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Security Findings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.securityFindings}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.serviceCost && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Service Cost:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.serviceCost}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.status && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Status:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.status}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.term && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Term:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.term}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.updatedBy && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Updated By:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.updatedBy}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.updatedOn && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Updated On:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal.updatedOn
                                  ? new Date(
                                      proposal.updatedOn
                                    ).toLocaleString()
                                  : ""}
                              </dd>
                            </div>
                          )}
                          {!!proposal?.wasteSavings && (
                            <div className="flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-36">
                                Waste Savings:
                              </dt>
                              <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                                {proposal?.wasteSavings}
                              </dd>
                            </div>
                          )}
                        </div>
                      </div>
                    </dl>
                  </>
                ) : (
                  <button
                    type="button"
                    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                      No Proposal Found.
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
