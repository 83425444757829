import { useState } from "react";
import { toast } from "react-toastify";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";
import Spinner from "../spinner/spinner";

export default function Verify() {
  const [loading, setLoading] = useState<boolean>(false);

  const resendEmail = () => {
    if (!loading) {
      AuthenticationService.resendEmailVerificationToken()
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const dbkProfile = commonService.dbkProfile;

  return (
      <div className="flex min-h-full flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex justify-center">
              <img
                className="h-16 w-auto"
                src={dbkProfile.imgUrl}
                alt={dbkProfile.name}
              />
            </div>
            <h2 className="mt-8 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
            Verify your email address.
            </h2>
            <p className="mt-2 text-sm text-center leading-6 text-gray-500">
              Not Received email?{" "}
              <a
                href="#"
                onClick={resendEmail}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                <Spinner show={loading} />
                Resend
              </a>
            </p>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>


  );
}
