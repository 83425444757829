import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import commonService from '../../app/service/commonService';
import InvoiceBreakdownDetailsModal from '../initiatives/modal/invoiceBreakdownDetailsModal';
// Interfaces


interface Props {
    data: DailyCategoryBurndownResultRaw[]

}

const convert = (raw: DailyCategoryBurndownResultRaw): DailyCategoryBurndownResult => {
    return {
        date: parseDate(raw.date),
        data: JSON.parse(raw.data)
    };
}

const parseDate = (date: string) => {
    const formattedDate = new Date(date);
    return formattedDate.toDateString();
}

// Sample usage with an array of raw data


export function MontlhyInvoiceReportChartAll(props: Readonly<Props>) {
    const [date, setDate] = useState<string>('');

    const [showDetailModal, setShowDetailModal] = useState(false);

    if (!props.data[0]?.data) {
        return null; // Or return some other fallback UI
    }
    const resultArray = props.data.map(convert) || [];

    const openDetails = (e: CategoricalChartState) => {
        setDate(e?.activeLabel?.split('T')[0] ?? commonService.getDate());
        openDetailModal();
    }

    const openDetailModal = () => {
        setShowDetailModal(true);
      };
    
      const closeDetailModal = () => {
        setShowDetailModal(false);
      };


    return (
        <>
        <InvoiceBreakdownDetailsModal show={showDetailModal} closeModal={closeDetailModal} date={date} chartData={resultArray} />
        <div className="overflow-hidden rounded-xl border border-gray-200 space-y-2 ">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6" >
                <div className="text-sm font-medium leading-6 text-gray-900">Daily Invoice Breakdown</div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4" style={{ width: '100%', height: '250px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={2400}
                        height={500}
                        data={resultArray}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        onClick={openDetails}>

                        <Tooltip />
                        {/*     <Legend />  */}
                        <YAxis width={0} />
                        <XAxis height={0} dataKey={'date'} />
                        <ReferenceLine x="2023-07-24" stroke="green"  ><Label value="Reservations Apply" position="top" /></ReferenceLine>
                        <ReferenceLine y={800} stroke="red" strokeDasharray="3 3"><Label value="On-Demand Budget" position="insideLeft" /></ReferenceLine>
                        <ReferenceLine y={700} stroke="orange" strokeDasharray="3 3" > <Label value="On-Demand Target" position="insideLeft" /></ReferenceLine>

                        {Object.entries(resultArray[0]?.data).map((type, typeIndex) => {
                            const dataKey = `data['${type[0]}']`;
                            const color = commonService.coldColors500[(typeIndex) % commonService.coldColors500.length]; // Cycle through colors
                            return <Bar key={dataKey} dataKey={dataKey} name={type[0].toString()} stackId='a' fill={color} />;
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div >
        </>
    );

}



