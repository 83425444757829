import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import commonService from "../app/service/commonService";

interface Props {
  pageList: any[];
  pathName: string;
}
export function Breadcrumb(props: Readonly<Props>) {
  const navigate = useNavigate();

  const onNavigate = (i: number, page: any) => {
    if (i !== props.pageList?.length - 1 && page.name === "Step" && commonService.planId) {
      commonService.parseNavigate(`${page.href}/${commonService.planId}`, navigate);
    }
  };

  return (
    <>
      {props?.pageList?.length ? (
        <nav
          className="flex mx-auto max-w-7xl px-4 pt-4 sm:px-6 lg:px-8"
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                    onClick={() => commonService.parseNavigate('/Initiatives', navigate)}
                  />
                  <span className="sr-only">Home</span>
                </a>
              </div>
            </li>
            {props?.pageList.map((page, i) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <a
                    href="#"
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                    onClick={() => onNavigate(i, page)}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      ) : (
        ""
      )}
    </>
  );
}
