import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import commonService from "../../../app/service/commonService";
import Pulse from "../../../app/pulse/pulse";
interface Props {
  show: boolean;
  closeModal: () => void;
  data: AzureGraphResultsItem;
}
export default function CloudResourceSnapshotModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [startDate, setStartDate] = useState<string>(commonService.getPrevMonthDate());
  const [endDate, setEndDate] = useState<string>(commonService.getDate());

  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [snapshotDisplayData, setSnapshotDisplayData] = useState<ChangeDetailDisplayData[]>([]);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      getListMonitorData();
    }
  }, [props?.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const getListMonitorData = () => {
    setLoading(true);
    const request: SnapshotRequest = {
      customerId: props?.data?.customerId,
      resourceId: props?.data?.resourceId,
      startDate: startDate,
      endDate: endDate,
    };
    CloudResourceService.getSnapshot(request)
      .then((responseData: any) => {
        setLoading(false);
        const result = responseData?.data?.result || {};
        parseSnapshotData(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const parseSnapshotData = (data: AzureCosmosDBCloudResourceSnapshotItem) => {
    const parseData: ChangeDetailDisplayData[] = [];
    (data?.resourceChanges || []).forEach((x, i) => {
      if(x.changes?.length > 0) {
        (x.changes || []).forEach((c, j) => {
          const item: ChangeDetailDisplayData = {
            id: `${i}-${j}`,
            attributeName: c.attributeName,
            changeType: c.changeType,
            oldValue: c.oldValue,
            newValue: c.newValue,
            startDate: x.startDate,
            endDate: x.endDate
          };
          parseData.push(item);
        });
      }
    });
    setSnapshotDisplayData(parseData);
  }

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Additional Info
            </Dialog.Title>



            <div>
                {loading ? (
                  <Pulse show={loading} />
                ) : (
                  <>
                    <div className="flex items-end">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="cloudstartDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Start Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="cloudstartDate"
                            name="cloudstartDate"
                            type="date"
                            required
                            value={startDate}
                            onChange={(e) => setStartDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !startDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="cloudendDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          End Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="cloudendDate"
                            name="cloudendDate"
                            type="date"
                            required
                            value={endDate}
                            onChange={(e) => setEndDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !endDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          onClick={getListMonitorData}
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Filter
                        </button>
                      </div>
                    </div>

                     <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Old Value
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            New Value
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {(snapshotDisplayData || [])?.map((item) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.attributeName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.oldValue}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.newValue}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {new Date(item.endDate).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> 
                  </>
                )}
              </div>



          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
