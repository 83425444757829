import { Dialog } from "@headlessui/react";
import { useRef, useState, useEffect } from "react";
import AuthenticationService from "../../../app/service/authService";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  data: User;
  show: boolean;
  closeModal?: any;
}

export default function UpdateRoleModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const cancelButtonRef = useRef(null);

  const [selectedRole, setSelectedRole] = useState(props.data?.role);

  useEffect(() => {
    setOpen(props.show);
    if (selectedRole != props.data?.role) {
      setSelectedRole(props.data?.role);
    }
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const updateRole = () => {
    const requestBody: UpdateUserRoleRequest = {
      userIds: [props.data?.id],
      role: selectedRole,
    };
    AuthenticationService.updateUserRole(requestBody)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        closeModal();
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        closeModal();
      });
  };

  const handleRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const roles = [
    {
      name: "Owner",
      id: "8e3af657-a8ff-443c-a75c-2fe8c4bcb635",
      description:
        "Grants the user create, edit, delete, and all special permissions",
    },
    {
      name: "Contributor",
      id: "b24988ac-6180-42a0-ab88-20f7382dd24c",
      description:
        "Grants the user create and edit permissions but not delete or special permissions",
    },
    {
      name: "Reader",
      id: "acdd72a7-3385-48ef-bd42-f606fba81ae7",
      description: "Grants the user the view permission only",
    },
  ];

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Role Management
            </Dialog.Title>
            <div className="mt-2">
              <fieldset>
                <legend className="sr-only">Roles</legend>
                <div className="space-y-5">
                  {roles.map((role) => (
                    <div key={role.id} className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id={role.id}
                          aria-describedby={`${role.id}-description`}
                          name="roles"
                          type="radio"
                          value={role.name}
                          checked={selectedRole === role.name}
                          onChange={() => handleRoleChange(role.name)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor={role.id}
                          className="font-medium text-gray-900"
                        >
                          {role.name}
                        </label>
                        <p
                          id={`${role.id}-description`}
                          className="text-gray-500"
                        >
                          {role.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            onClick={() => updateRole()}
            disabled={
              props.data?.role ? selectedRole === props.data.role : true
            }
            style={{
              // Add inline style to change background color when the button is disabled
              backgroundColor:
                props.data?.role && selectedRole === props.data.role
                  ? "rgb(209, 213, 219)"
                  : "rgb(79, 70, 229)",
            }}
          >
            Update
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
