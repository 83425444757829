import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import ProposalService from "../../../app/service/proposalService";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  account?: AccountItem;
  closeModal: (refresh?: boolean) => void;
  refreshDetails: (refresh?: boolean) => void;
}

const errorState: any = {
  status: { hasError: false, required: true },
  comment: { hasError: false, required: true },
};

export default function AccountStatusModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [account, setAccount] = useState<CreateOrUpdateAccountRequest | any>(
    {}
  );

  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const cancelButtonRef = useRef(null);

  const [hasProposal, setHasProposal] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.show);
    if (props?.show) {
      setHasProposal((props?.account?.proposals?.length ?? 0) > 0);
      initAccount();
      checkError();
    }
  }, [props.show]);

  useEffect(() => {
    checkError();
  }, [account]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const initAccount = () => {
    const proposals = props?.account?.proposals || [];
    let proposal;
    if (proposals?.length > 0) {
      proposal = proposals[proposals?.length - 1];
    }
    setAccount({
      id: proposal?.id,
      name: props?.account?.name,
      status: proposal?.status,
      comment: "",
    });
  };

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(account));
    for (const key of Object.keys(errorState) || []) {
      onInputChange(key, model[key] || "");
    }
  };

  const createAccount = () => {
    onCheckError();
    checkError();
    if (!hasError && !loading) {
      console.log(account);
      setLoading(true);
      ProposalService.updateStatus(account)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            props.refreshDetails(true);
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const input = commonService.onInputChange(fieldName, value, account, error);
    setAccount(input.model);
    setError(input.error);
  };

  const checkError = () => {
    setHasError(commonService.checkError(errorState, account));
  };

  return (
    <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          {!hasProposal && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    There is no active proposal.
                  </div>
                </div>
              </div>
            </div>
          )}

          {hasProposal && (
            <div>
              <div className="mt-3 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-base text-center font-semibold leading-6 text-gray-900"
                >
                  Update Status: {props?.account?.name}
                </Dialog.Title>
                <div className="mt-2">
                  <form className="space-y-6">
                    <div>
                      <label
                        htmlFor="status"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Status
                      </label>
                      <div className="mt-1 mb-1">
                        <select
                          id="status"
                          name="status"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={account.status}
                          required
                          onChange={(e) =>
                            onInputChange("status", e?.target?.value)
                          }
                        >
                          {(
                            Object.values(
                              commonService.accountSalesProposalStatus
                            ) || []
                          )?.map((val: any) => (
                            <option value={val} key={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Comment
                      </label>
                      <div className="mt-2">
                        <input
                          id="comment"
                          name="comment"
                          type="text"
                          required
                          value={account.comment}
                          onChange={(e) =>
                            onInputChange("comment", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.comment?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.comment?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Comment is required.
                      </p>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3 sm:grid-cols-2">
            {hasProposal && (
              <button
                type="submit"
                disabled={loading || !hasProposal}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={() => createAccount()}
              >
                <Spinner show={loading} />
                Update
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
  );
}
