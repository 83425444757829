import { FinopsPostureStats } from "../../components/initiatives/finopsPostureStats";
import InitiativesTable from "../../components/initiatives/initiativesTable";
import { useEffect, useState } from "react";
import commonService from "../service/commonService";

export default function InitiativesDashboard() {
  const [selectedProject, setSelectedProject] = useState<ProjectItem>();
  const [loadingProject, setLoadingProject] = useState<boolean>(false); 

  useEffect(() => {
    commonService.getAllUsers();
  }, []);

  const onChangeProject = (projectId: ProjectItem) => {
    setSelectedProject(projectId);
  }
  return (
    <div className="relative isolate overflow-hidden">
      <FinopsPostureStats
        onProjectChange={(e) => onChangeProject(e)}
        setLoadingProject={(e) => setLoadingProject(e)}
      />
      <InitiativesTable
        projectId={selectedProject?.id}
        project={selectedProject}
        isLoadingProject={loadingProject}
      />
      {/* <TargetStats /> */}
    </div>
  );
}
