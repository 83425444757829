import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Pulse from "../../app/pulse/pulse";
import commonService from "../../app/service/commonService";
import ProposalService from "../../app/service/proposalService";
import ProposalModal from "../initiatives/modal/proposalModal";

export default function PreSaleDashboard() {
  const [proposal, setProposal] = useState<AccountSalesProposalItem | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [showProposalModal, setShowProposalModal] = useState<boolean>(false);

  useEffect(() => {
    getProposalsByCustomer();
  }, []);

  const getProposalsByCustomer = () => {
    setLoading(true);
    ProposalService.getProposalsByCustomer()
      .then((response: any) => {
        setLoading(false);
        let result: CreateOrUpdateProposalRequest[] = response?.data?.result || [];
        if (result.length > 0) {
          result = result.filter(x => x.isActive && x.status === commonService.accountSalesProposalStatus.Submitted);
          result.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);
            return dateB.getTime() - dateA.getTime();
          });
          setProposal(result[0]);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openProposalModal = (mode: string) => {
    setMode(mode);
    setShowProposalModal(true);
  };

  const closeProposalModal = (refresh?: boolean) => {
    setShowProposalModal(false);
    if(refresh) {
      getProposalsByCustomer();
    }
  };

  return (
    <>
      <ProposalModal
        show={showProposalModal}
        closeModal={closeProposalModal}
        mode={mode}
        proposal={proposal}
      />
      <div className="py-10">
        <main>
          <div className="relative bg-white">
            <img
              className="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80"
              alt=""
            />
            <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
              <div className="px-6 pb-24 pt-2 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-2">
                <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
                  {loading ? (
                    <Pulse show={loading} />
                  ) : (
                    <>
                      {proposal ? (
                        <>
                          <h2 className="text-base font-semibold leading-8 text-indigo-600">
                            FinOps as a service
                          </h2>
                          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Great news!
                          </p>
                          <p className="mt-6 text-lg leading-8 text-gray-600">
                            We found potential for significat savings. We have
                            identified a number of opportunities to optimize
                            your cloud spend and usage, and provide actionable
                            insights to help you make more informed decisions.
                          </p>
                          {!proposal?.isPreProposal ? (
                            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                              <div
                                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                                >
                                  <dt className="text-sm leading-6 text-gray-600">
                                  Commitments
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                    {proposal?.commitmentsSavings ? `$${proposal?.commitmentsSavings}`: `$0`}
                                  </dd>
                                </div>
                                <div
                                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                                >
                                  <dt className="text-sm leading-6 text-gray-600">
                                  Optimization
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                  {proposal?.optimizationSavings ? `$${proposal?.optimizationSavings}`: `$0`}
                                  </dd>
                                </div>
                                <div
                                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                                >
                                  <dt className="text-sm leading-6 text-gray-600">
                                  Resources
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                    {proposal?.resourceCount ?? 0}
                                  </dd>
                                </div>
                                <div
                                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                                >
                                  <dt className="text-sm leading-6 text-gray-600">
                                  Savings
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                    {proposal?.commitmentsSavings ?? 0}
                                  </dd>
                                </div>
                            </dl>
                          ) : (
                            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                              <div
                                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                                >
                                  <dt className="text-sm leading-6 text-gray-600">
                                  Commitments
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                                    {proposal?.commitmentsSavings ? `$${proposal?.commitmentsSavings}`: `$0`}
                                  </dd>
                                </div>
                            </dl>
                          )}
                          <p className="mt-6 text-lg leading-8 text-gray-600">
                            Whats next?
                          </p>
                          <div className="mt-8 flex justify-between w-80">
                            <a
                              href="#"
                              className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => openProposalModal(commonService.accountSalesProposalStatus.Approved)}
                            >
                              Accept
                            </a>
                            <a
                              href="#"
                              className="inline-flex rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                              onClick={() => openProposalModal(commonService.accountSalesProposalStatus.Rejected)}
                            >
                              Reject
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <h2 className="text-base font-semibold leading-8 text-indigo-600">
                            FinOps as a service
                          </h2>
                          <p className="mt-6 text-lg leading-8 text-gray-600">
                            We are working on your proposal, you will be
                            notified by email once is completed.
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
