import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import OpportunityService from "../../../app/service/opportunityService";
import ConfirmationModal from "../../reports/modal/confirmationModal";
import { ModalWrapper } from "../../common/modalWrapper";
import { ProjectForm } from "../../../app/common/commonControls";
interface Props {
  data?: MonthCostCategoryComparisonResult[];
  show: boolean;
  closeModal?: any;
  projectItem?: ProjectItem;
  isEdit?: boolean;
  refreshDetails?: any;
  refreshProject?: any;
  fromResourceModal?: boolean;
  reloadProject?: any;
}

export default function ProjectModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [project, setProject] = useState<ProjectItem | any>(props.projectItem);

  const cancelButtonRef = useRef(null);

  const editProjectName = props.projectItem?.name;

  const deleteProjectModal = {
    title: "Delete Project",
    message: "Are you sure you want to delete the Project",
  };

  const [showDeleteProjectModal, setShowDeleteProjectModal] =
    useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<string>("");
  const [loadingDeleteProject, setLoadingDeleteProject] =
    useState<boolean>(false);

  useEffect(() => {
    setOpen(props.show);
    initProject();
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const initProject = () => {
    const currentProject = props.isEdit ? props.projectItem : commonService?.initProjectItem();
    const data: ProjectItem = JSON.parse(
      JSON.stringify(currentProject || {})
    );
    if (!props.isEdit) {
      data.maturity = commonService.maturityList[0];
      data.phase = commonService.phaseList[0];
    }
    setProject(data);
  };

  const createProject = () => {
    const userData = commonService.userDetail();
    const body: CreateOrUpdateProjectRequest = {
      accountId: commonService.getAccountId(),
      ownerId: userData.id,
      name: project.name,
      description: project.description,
      maturity: project.maturity,
      phase: project.phase,
    };
    if (props?.isEdit) {
      body.id = project.id;
    }
    setLoading(true);
    if (props?.isEdit) {
      OpportunityService.updateProject(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoading(false);
        });
    } else {
      OpportunityService.createProject(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
            props.reloadProject(response?.data?.result);
            if(props?.fromResourceModal) {
              const data = {
                project: response?.data?.result?.id || "",
                plan: "",
                step: ""
              }
              props?.refreshProject(data);
            }
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoading(false);
        });
    }
  };

  const openDeleteProjectModal = (e: any, project: ProjectItem) => {
    e?.preventDefault();
    setDeleteMessage(`${deleteProjectModal.message}: ${project.name} ?`);
    setShowDeleteProjectModal(true);
    setOpen(false);
  };

  const closeDeleteProjectModal = () => {
    setShowDeleteProjectModal(false);
    setOpen(true);
  };

  const onDeleteProject = () => {
    if (project) {
      setLoadingDeleteProject(true);
      OpportunityService.deleteProject(project.id)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeleteProject(false);
          if (response?.data?.isSuccess) {
            closeDeleteProjectModal();
            props.refreshDetails(true);
            closeModal();
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoadingDeleteProject(false);
        });
    }
  };

  const onInputChange = (projectModel: ProjectItem) => setProject(projectModel);

  const onCheckError = (isError: boolean) => setHasError(isError);

  return (
    <>
      <ConfirmationModal
        show={showDeleteProjectModal}
        title={deleteProjectModal.title}
        message={deleteMessage}
        loading={loadingDeleteProject}
        closeModal={() => closeDeleteProjectModal()}
        save={() => onDeleteProject()}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {props?.isEdit
                  ? `Edit Project:  ${editProjectName}`
                  : "Add Project"}
              </Dialog.Title>
              <div className="mt-2">
                <ProjectForm project={project} onInputChange={onInputChange} onCheckError={onCheckError} show={props?.show} />
              </div>
            </div>
          </div>

          <div
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              props?.isEdit ? "sm:grid-cols-3" : "sm:grid-cols-2"
            )}
          >
            <button
              type="submit"
              disabled={hasError || loading}
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                hasError || loading ? "disabled:opacity-75" : "",
                props.isEdit ? "sm:col-start-3" : "sm:col-start-2"
              )}
              onClick={() => createProject()}
            >
              <Spinner show={loading} />
              {props?.isEdit ? "Update" : "Create"}
            </button>
            {props.isEdit && (
              <button
                type="button"
                disabled={loading}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={(e) => openDeleteProjectModal(e, project)}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
