import { useState } from "react";
import commonService from "../service/commonService";


export default function Support() {
  const dbkProfile = commonService.dbkProfile;
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <>
    <div className="bg-white shadow">
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-12 w-auto mt-4"
                    src={dbkProfile.imgUrl}
                    alt={dbkProfile.name}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
    <div className="mx-auto max-w-7xl px-6 py-8 sm:pt-8 lg:px-8">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="lg:col-span-5">
          <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
            Support
          </h2>

          <div className="flex items-center justify-between mt-2">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                    I'm not a robot
                  </label>
                </div>
                </div>

          {isChecked && <p className="mt-4 text-pretty text-base leading-7 text-gray-600">
          For support contact: {' '}
            <a href="mailto:Support@floatfinops.com" className="font-semibold text-indigo-600 hover:text-indigo-500">
            Support@floatfinops.com
            </a>{' '}
            team.
          </p>}
        </div>
    </div>
  </div>

</>
  );
}
