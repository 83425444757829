import { useState, useEffect } from "react";
import Pulse from "../../../app/pulse/pulse";
import commonService from "../../../app/service/commonService";
import ReportService from "../../../app/service/reportService";
import { toast } from "react-toastify";
import Spinner from "../../../app/spinner/spinner";
interface Props {
  resource: OpportunityPlanResourceItem | MonthCostCategoryResult | undefined | any;
  date: string;
  isCreate?: boolean;
}

export default function ResourceDetialsTable(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [resourceData, setResourceData] = useState<
    MonthCostSummaryByResourceResponse[]
  >([]);

  useEffect(() => {
    setDateRange(initDate());
    getDailyCost();
  }, [props?.date]);

  const initDate = () => {
    return props?.date || commonService.getDate();
  };

  const [dateRange, setDateRange] = useState<string>(initDate());

  const getDailyCost = () => {
    let resourceId = "";
    if(props?.isCreate) {
      resourceId = props?.resource?.resourceId || '';
    } else {
      resourceId = props?.resource?.cspResourceId || '';
    }
    if (resourceId && dateRange) {
      const request: MonthCostSummaryByResourceRequest = {
        customerId: commonService.getAccountId(),
        date: dateRange || "",
        resourceId: resourceId,
      };
      setLoading(true);
      ReportService.getMonthCostSummaryByResourceId(request)
        .then((response: any) => {
          const result = response?.data?.result || [];
          setResourceData(result);
          setLoading(false);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onDateChange = (e: string) => {
    setDateRange(e);
  };

  return (
    <>
      <div className="flex justify-end">
        <div className="w-72 mr-3 flex mb-3">
          <input
            id="startOn"
            name="startOn"
            type="date"
            required
            value={dateRange}
            onChange={(e) => onDateChange(e?.target?.value)}
            className={commonService.classNames(
              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              !dateRange
                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                : ""
            )}
          />
          <button
            type="button"
            disabled={!dateRange || loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              !dateRange || loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => getDailyCost()}
          >
            <Spinner show={loading} />
            Filter
          </button>
        </div>
      </div>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {loading ? (
            <Pulse show={loading} />
          ) : (
            <>
              {resourceData?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Meter Category
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Meter SubCategory
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Resource Location
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Records
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Consume Units
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Cost
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Unit Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        PayG Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Unit Of Measure
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Pricing Model
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {resourceData.map(
                      (item: MonthCostSummaryByResourceResponse) => (
                        <tr key={`${item.resourceId}${item.cost}`}>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.meterCategory}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.meterSubCategory}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.resourceLocation}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.recordAmount}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.consumeUnits}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.cost}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.unitPrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.payGPrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.unitOfMeasure}
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {item.pricingModel}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <button
                  type="button"
                  className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                    />
                  </svg>
                  <span className="mt-2 block text-sm font-semibold text-gray-900">
                    No Data Found.
                  </span>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
