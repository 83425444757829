import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import AuthenticationService from "../../../app/service/authService";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  account?: AccountItem;
  isEdit?: boolean;
  closeModal: (refresh?: boolean) => void;
  refreshDetails: (refresh?: boolean) => void;
}

const errorState: any = {
  name: { hasError: false, required: true },
  website: { hasError: false, required: true },
  size: { hasError: false, required: true },
};

export default function AccountModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [account, setAccount] = useState<CreateOrUpdateAccountRequest | any>({});

  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const cancelButtonRef = useRef(null);

  const submitBtnText = props?.isEdit ? 'Update': 'Create';

  useEffect(() => {
    setOpen(props.show);
    if(props?.show) {
      initAccount();
      checkError();
    }
  }, [props.show]);

  useEffect(() => {
    checkError();
  }, [account]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const initAccount = () => {
    let newAccount: any = {};
    if(!props?.isEdit) {
      newAccount.name = "";
      newAccount.parentId = commonService.getAccountId();
      newAccount.size = commonService.companySizeList[0].value;
      newAccount.website = "";
      newAccount.mATenantId = props?.account?.mATenantId;
    } else {
      newAccount = JSON.parse(JSON.stringify(props?.account || {}));
    }
    setAccount(newAccount);
  };

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(account));
    for (const key of Object.keys(errorState) || []) {
      onInputChange(key, model[key] || "");
    }
  };

  const createAccount = () => {
    onCheckError();
    checkError();
    if (!hasError && !loading) {
      const body: CreateOrUpdateAccountRequest = {
        parentId: account.parentId,
        name: account.name,
        size: account.size,
        website: account.website,
        saleStatus: account.saleStatus,
        mATenantId: account.mATenantId
      };
      if(props?.isEdit) {
        body.id = account.id;
      }
      setLoading(true);
      if(props?.isEdit) {
        AuthenticationService.updateAccount(
          body
        )
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              props.refreshDetails(true);
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      } else {
        AuthenticationService.createCustomer(
          body,
          commonService.getAccountId()
        )
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              props.refreshDetails(true);
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      }
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = !value;
    const model = JSON.parse(JSON.stringify(account));
    model[fieldName] = value;
    setAccount(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (errorState[key]?.required && !account[key]) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              { props?.isEdit ? "Edit Account": "Create Account" }
            </Dialog.Title>
            <div className="mt-2">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      value={account.name}
                      onChange={(e) => onInputChange("name", e?.target?.value)}
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        commonService.hasFieldError(error, 'name')
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                    />
                  </div>
                  {commonService.hasFieldError(error, 'name') && (
                    <p className="mt-2 text-sm text-red-600" id="order-error">
                      Name is required.
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Website
                  </label>
                  <div className="mt-2">
                    <input
                      id="website"
                      name="website"
                      type="text"
                      required
                      value={account.website}
                      onChange={(e) =>
                        onInputChange("website", e?.target?.value)
                      }
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        commonService.hasFieldError(error, 'website')
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                    />
                  </div>
                  {commonService.hasFieldError(error, 'website') && (
                    <p className="mt-2 text-sm text-red-600" id="order-error">
                      Website is required.
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="size"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Size
                  </label>
                  <div className="mt-1 mb-1">
                    <select
                      id="size"
                      name="size"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                      value={account.size}
                      required
                      onChange={(e) => onInputChange("size", e?.target?.value)}
                    >
                      {commonService.companySizeList?.map(
                        (size: { name: string; value: string }) => (
                          <option value={size.value} key={size.value}>
                            {size.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3 sm:grid-cols-2">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createAccount()}
          >
            <Spinner show={loading} />
            {submitBtnText}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
