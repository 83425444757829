import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import ProposalService from "../../app/service/proposalService";
import DelegatedReaderAccessDocumentModal from "../initiatives/modal/delegatedReaderAccessDocumentModal";

export default function AccessReview() {
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );
  const [showDelegatedReaderAccessModal, setShowDelegatedReaderAccessModal] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(true);
  };

  const closeDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(false);
  };

  const createProposal = () => {
    if (!loading) {
      const body: CreateOrUpdateProposalRequest | any = {
        CustomerId: commonService.getAccountId(),
        DisplaySavingsPercentage: null,
        CurrentInvoiceTotal: null,
        CommitmentsCurrent: null,
        CommitmentsSavings: null,
        LicensingCurrent: null,
        LicensingSavings: null,
        OptimizationSavings: null,
        WasteSavings: null,
        RateOptimizationSavings: null,
        ResourceCount: 0,
        SecurityFindings: 0,
        GovernanceFindings: 0,
        GuaranteeSavings: null,
        Status: null,
        Notes: null,
        DecisionComments: null,
        Plan: null,
        Term: null,
        PaymentTerm: null,
        ServiceCost: null,
        IsPreProposal: true,
      };
      setLoading(true);
      ProposalService.createProposal(body)
        .then((response: any) => {
          setLoading(false);
          if (response?.data?.isSuccess) {
            window.location.reload();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <DelegatedReaderAccessDocumentModal
        show={showDelegatedReaderAccessModal}
        closeModal={() => closeDelegatedReaderAccessModal()}
        subscriptionList={subscriptionList}
      />
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We are working on your proposal. Pending access. if you have
            completed this step, it could take up to 24 hours to display.
          </h2>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="#"
              onClick={openDelegatedReaderAccessModal}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
