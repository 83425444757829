import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, Customized, Dot } from 'recharts';
import { ChartTooltip } from '../../../app/common/commonControls';
import commonService from '../../../app/service/commonService';
// Interfaces
interface IRsCostDataset {
    date: string;
    vm: number;
    disk: number;
    cost: number;
    os: number;
}

interface Props {
    data: IRsCostDataset[]
    annotations?: ResourceBurnRateChartAnnotationProps[];
}

export function ResourceBurnRateChart(props: Readonly<Props>) {
    const sampleDataItem = props.data[0] || {};
    const dataKeys = Object.keys(sampleDataItem);
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey={'date'} height={0} />
                <Tooltip content={<ChartTooltip />} />
                {dataKeys.map((key, index) => (
                    key !== 'date' && <Bar key={key} dataKey={key} stackId="a" fill={commonService.coldColors500[index % commonService.coldColors500.length]} />
                ))}
                {props.annotations && props.annotations.map((annotationProps, index) => (
                    <Customized key={index} component={<ResourceBurnRateChartAnnotation {...annotationProps} />} />
                ))}


            </BarChart>
        </ResponsiveContainer>
    );

}

interface ResourceBurnRateChartAnnotationProps {
    positionX: number;    // X position for the cross
    textContent: string;  // Text content
    yOffset: number; // vertical offset
}

const ResourceBurnRateChartAnnotation: React.FC<ResourceBurnRateChartAnnotationProps> = ({ positionX, textContent, yOffset }) => {
    const stroke = '#000',
        fill = '#818CF8';

    // Set position for text
    const textX = positionX + 10;  // Set a margin (10 here) or adjust as needed
    const textY = 10 + yOffset;

    return (
        <>
            <text x={textX - 20} y={textY} fill="black" textAnchor="end" dominantBaseline="central">
                {textContent}
            </text>
            <Dot
                r={7}
                cy={textY + 2}
                cx={positionX}
                stroke={stroke ?? '#000'}
                fill={fill ?? 'none'}
            />
        </>
    );
};
