import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import InvoiceSummary from "../invoiceSummary";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  data: MonthCostCategoryComparisonResult[];
  show: boolean;
  closeModal?: any;
}
export default function DetailMonthCostCategoryComparison(
  props: Readonly<Props>
) {
  const [open, setOpen] = useState(props.show);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Detail for Category: {(props?.data || [])[0]?.category}, Region:{" "}
              {(props?.data || [])[0]?.resourceLocation}
            </Dialog.Title>
            <div className="mt-2">
              <InvoiceSummary data={props?.data || []} />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3 justify-items-end">
          <button
            type="button"
            className="mt-3 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 w-20"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
