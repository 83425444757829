import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import GrantAccessModal from "../initiatives/modal/grantAccessModal";
import InvoiceModal from "../initiatives/modal/invoiceModal";

export default function CallToActionPreSale() {
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>([]);
  const [showGrantAccessModal, setShowGrantAccessModal] =
  useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const openInvoiceModal = () => {
    setShowInvoiceModal(true);
  };

  const closeInvoiceModal = () => {
    setShowInvoiceModal(false);
  };

  const refreshDetails = (refresh?: boolean) => {
    if (refresh) {
      commonService.parseNavigate("/newAccountProsessing", navigate);
    }
  };

  const getAllSubscriptions = () => {
    setLoading(true);
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        setLoading(false);
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        setLoading(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openGrantAccessModal = () => {
    if(!loading) {
      setShowGrantAccessModal(true);
    }
  };

  const closeGrantAccessModal = () => {
    setShowGrantAccessModal(false);
  };

  return (
    <>
      { showInvoiceModal && <InvoiceModal
        show={showInvoiceModal}
        closeModal={closeInvoiceModal}
        refreshDetails={refreshDetails}
      />}
      <GrantAccessModal 
      show={showGrantAccessModal}
      closeModal={closeGrantAccessModal}
      subscriptionList={subscriptionList} />
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
            <div className="lg:pr-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  FinOps as a Service
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  A better way to operate
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Allow to get a birds eye view of your cloud spend and usage,
                  and provide actionable insights to
                </p>
                <div className="mt-8">
                  <a
                    href="#"
                    className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={openInvoiceModal}
                  >
                    Import my Invoice
                  </a>
                  {!loading && <a
                    href="#"
                    className="text-indigo-600 hover:text-indigo-900 ml-12"
                    onClick={() => openGrantAccessModal()}
                  >
                    Skip step and Grant Acces
                  </a>}
                </div>
                <figure className="mt-16 border-l border-gray-200 pl-8 text-gray-600">
                  <blockquote className="text-base leading-7">
                    <p>
                      "Float FinOps As a Service is a game changer for our
                      business. We now have a clear understanding of our cloud
                      spend and usage, and can make more informed decisions with
                      actionable items:
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 flex gap-x-4 text-sm leading-6">
                    <img
                      src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                      alt=""
                      className="h-6 w-6 flex-none rounded-full"
                    />
                    <div>
                      <span className="font-semibold text-gray-900">
                        Maria Hill
                      </span>{" "}
                      – CEO
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[44rem] md:-ml-4 lg:ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </>
  );
}
