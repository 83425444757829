import axios from "axios";
import commonService from "./app/service/commonService";
import { msalInstance } from ".";
import { loginRequest } from "./authConfig";

// const baseURL = "https://glass.dbk.cloud/api/";
  const baseURL = `${process.env.REACT_APP_API_ENDPOINT}/api/`;
//const baseURL = "https://localhost:7268";
//const testBaseURL = "https://localhost:7268";
const headers = {
    "Content-type": "application/json",
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
    'Access-Control-Allow-Origin': '*'
};

const secureAxios = axios.create({
    baseURL: baseURL,
    headers: headers
});

const guestAxios = axios.create({
    baseURL: baseURL,
    headers: headers
});

secureAxios.interceptors.request.use(async (config) => {
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            account: account
        });

        if (accessTokenResponse) {
            const accessToken = accessTokenResponse.accessToken;
            //console.log("got token: ", accessTokenResponse)
            if (config.headers && accessToken) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
            }
        }
    }


    const user = JSON.parse(localStorage.getItem('currentUser') ?? '{}');
    const userId = user?.id;
    const customerId = commonService.selectedAccount?.id ?? user?.accountId;
    config.headers.userId = userId || null;
    config.headers.customerId = customerId || null;
    return config;
}, (error) => {
    const status = error.response?.status || 500;
    if (status === 401) {
         window.location = <any>'/';
    }
    return error;
});

export {
    secureAxios,
    guestAxios
}