import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  users: OpportunityPlanContactsItem[];
  task: OpportunityPlanResourceTaskItem;
  onUpdateStatus: (
    completedOn: string,
    isRemoved?: boolean,
    userId?: string
  ) => void;
}

export default function TaskStatusUpdateModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [completedOn, setCompletedOn] = useState<string>(
    commonService.currentDate()
  );

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    setCompletedOn(commonService.currentDate());
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const updateStatus = () => {
    setLoading(true);
    props.onUpdateStatus(completedOn, false, user);
  };

  const onDateChange = (value: string) => {
    setCompletedOn(value);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Update Status
            </Dialog.Title>
            <div className="flex">
              <div className="mt-2 flex-1">
                <form className="space-y-6">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Select User
                    </label>
                    <div className="mt-1 mb-1">
                      <select
                        id="user"
                        name="user"
                        className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={user}
                        required
                        onChange={(e) => setUser(e?.target?.value)}
                      >
                        {props.users.map((u: OpportunityPlanContactsItem) => (
                          <option value={u.id} key={u.id}>
                            {u.externalUserName || u.user?.username}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="mt-2 flex-1">
                <form className="space-y-6">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="completedOn"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Completed On
                    </label>
                    <div className="mt-1">
                      <input
                        id="completedOn"
                        name="completedOn"
                        type="date"
                        required
                        value={completedOn}
                        onChange={(e) => onDateChange(e?.target?.value)}
                        className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            }
            onClick={() => updateStatus()}
          >
            <Spinner show={loading} />
            Update
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
