import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import DelegatedReaderAccessDocumentModal from "./delegatedReaderAccessDocumentModal";
import { toast } from "react-toastify";
import AuthenticationService from "../../../app/service/authService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  subscriptionList: SubscriptionItem[];
}

const errorState: any = {
  tenantId: { hasError: false, required: true },
};

export default function GrantAccessModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(true);
  
  const [tenantId, setTenantId] = useState<string>("");

  const cancelButtonRef = useRef(null);

  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );
  const [showDelegatedReaderAccessModal, setShowDelegatedReaderAccessModal] =
    useState<boolean>(false);
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      setTenantId("");
      setSubscriptionList(props?.subscriptionList);
      checkError();
    }
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props?.closeModal(refresh);
  };

  const action = () => {
    onCheckError();
    checkError();
    if (!hasError && !loading) {
      const body: grandAccessRequest = {
        customerId: commonService.getAccountId(),
        tenantId: tenantId,
      };
      setLoading(true);
      AuthenticationService.grantAccess(body)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            openDelegatedReaderAccessModal();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onCheckError = () => {
    onInputChange(tenantId);
  };

  const onInputChange = (value: string) => {
    error.tenantId.hasError = !value;
    setTenantId(value);
  };

  const checkError = () => {
    setHasError(!tenantId);
  };

  const openDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(true);
  };

  const closeDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(false);
    window.location.reload();
  };

  return (
    <>
      <DelegatedReaderAccessDocumentModal
        show={showDelegatedReaderAccessModal}
        closeModal={() => closeDelegatedReaderAccessModal()}
        subscriptionList={subscriptionList}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                Grant Access
              </Dialog.Title>
              <div className="mt-2">
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="tenantId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Tenant ID
                    </label>
                    <div className="mt-2">
                      <input
                        id="tenantId"
                        name="tenantId"
                        type="text"
                        required
                        value={tenantId}
                        onChange={(e) => onInputChange(e?.target?.value)}
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.tenantId?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.tenantId?.hasError && (
                      <p className="mt-2 text-sm text-red-600" id="order-error">
                        Tenant ID is required.
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="submit"
              disabled={loading}
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                loading ? "disabled:opacity-75" : ""
              )}
              onClick={() => action()}
            >
              <Spinner show={loading} />
              Grant Access
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
