import { secureAxios } from "../../http-common";

const portalUrl = 'portal';

const getDailyCategoryBurndown = async (customerId: string, from: string, to: string) => {
    
    return secureAxios.get<Array<DailyCategoryBurndownResultRaw>>(`${portalUrl}/Report/GetDailyCategoryBurndown?customerId=${customerId}&from=${from}&to=${to}`);
};

const getMonthCostCategoryComparison = async (customerId: string, month: number, year: number) => {
    return secureAxios.get<Array<MonthCostCategoryComparisonResult>>(`${portalUrl}/Report/GetMonthCostCategoryComparison?customerId=${customerId}&month=${month}&year=${year}`);
};


const getTagSplit = async (customerId: string, from: string, to: string) => {
    return secureAxios.get<Array<TagsSplitResult>>(`${portalUrl}/Report/GetTagsSplit?customerId=${customerId}&from=${from}&to=${to}`);
};

const getMonthlyCost = async(body: MonthCostCategoryRequest) => {
    return secureAxios.post<Array<MonthCostCategoryResult>>(`${portalUrl}/Report/GetMonthlyCost`, body);
}

const getDailyCost = async(body: DayCostCategoryRequest) => {
    return secureAxios.post<Array<DayCostCategoryResponse>>(`${portalUrl}/Report/GetDailyCost`, body);
}

const getMonthCostSummaryByResourceId = async(body: MonthCostSummaryByResourceRequest) => {
    return secureAxios.post<Array<MonthCostSummaryByResourceResponse>>(`${portalUrl}/Report/GetMonthCostSummaryByResourceId`, body);
}

const getInvoiceStatus = async() => {
    return secureAxios.get<Array<InvoiceStatusResult>>(`${portalUrl}/invoice/list`);
}

const getInvoiceStatusSync = () => {
    return secureAxios.get<Array<InvoiceStatusResult>>(`${portalUrl}/invoice/list`);
}

const uploadInvoice = async (formData: FormData, year: number, month: number, invoiceName: string, subscriptionId: string) => {
    return secureAxios.post<AuthenticationResponse>(`${portalUrl}/invoice/uploadInvoice?year=${year}&month=${month}&invoiceName=${invoiceName}&subscriptionId=${subscriptionId}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        }
    });
};

const deleteInvoice = async (body: string[]) => {
    return secureAxios.post<AuthenticationResponse>(`${portalUrl}/invoice/deleteInvoice`, body);
};

const ReportService = {
    getDailyCategoryBurndown,
    getMonthCostCategoryComparison,
    getTagSplit,
    getMonthlyCost,
    getDailyCost,
    getMonthCostSummaryByResourceId,
    getInvoiceStatus,
    uploadInvoice,
    deleteInvoice,
    getInvoiceStatusSync
};

export default ReportService;