import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import commonService from "../../app/service/commonService";

interface Props {
  chartData: any[];
  xAxisKey: string;
  lineKey: string;
  lineKey2?: string;
  factor?: number;
  isPercentage?: boolean;
}

export function CommonLineChart(props: Readonly<Props>) {

  const getStrokeColor = () => {
    if(!props.factor || props?.factor === 1) {
      return commonService.coldColors500[1];
    } else if(props?.factor > 1) {
      return 'green';
    }
    return 'red';
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={400}
        data={props?.chartData || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props?.xAxisKey} hide />
        { props?.isPercentage ? <YAxis domain={[0, 100]} />: <YAxis /> }
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={props?.lineKey}
          stroke={commonService.coldColors500[0]}
          activeDot={{ r: 8 }}
        />
        {props?.lineKey2 && (
          <Line
            type="monotone"
            dataKey={props?.lineKey2}
            stroke={getStrokeColor()}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
