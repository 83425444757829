import { InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Pulse from "../../app/pulse/pulse";
import AuthenticationService from "../../app/service/authService";
import commonService from "../../app/service/commonService";
import ProposalService from "../../app/service/proposalService";
import Spinner from "../../app/spinner/spinner";
import { TableHead } from "../common/sortingControl";
import AccountModal from "../initiatives/modal/accountModal";
import AccountStatusModal from "../initiatives/modal/accountStatusModal";
import CreateProposalModal from "../initiatives/modal/createProposalModal";
import ProposalDetailModal from "../initiatives/modal/proposalDetailModal";

export default function ResellerProposal() {
  const [customers, setCustomers] = useState<AccountItem[]>([]);
  const [customersFilteredData, setCustomersFilteredData] = useState<
    AccountItem[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showProposalDetailModal, setShowProposalDetailModal] = useState(false);
  const [showCreateProposalModal, setShowCreateProposalModal] = useState(false);

  const [selectedProposal, setSelectedProposal] = useState<
    AccountSalesProposalItem[]
  >([]);

  const [selectedCustomer, setSelectedCustomer] =
    useState<AccountSalesProposalItem>();

  const [currentAccountId, setCurrentAccountId] = useState<string>("");

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");

  useEffect(() => {
    setCurrentAccountId(
      commonService.selectedAccount?.id ||
        customerId ||
        commonService.userDetail()?.accountId
    );
    getAllProposals();
  }, []);

  const getCustomers = () => {
    return (commonService.userDetail()?.accounts || []).filter(
      (x) => x.type === "Customer"
    );
  };

  const getAllProposals = () => {
    setLoading(true);
    ProposalService.getAllProposals()
      .then((response: any) => {
        const result: AccountSalesProposalItem[] = response?.data?.result || [];
        const currentCustomers = getCustomers();
        currentCustomers.forEach((x) => {
          x.proposals = result.filter((r) => r.customerId === x.id);
        });
        setCustomers(currentCustomers);
        setCustomersFilteredData(currentCustomers);
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const openProposal = (customer: AccountItem) => {
    setSelectedProposal(customer.proposals || []);
    setShowProposalDetailModal(true);
  };

  const closeProposalModal = () => {
    setShowProposalDetailModal(false);
  };

  const openCreateProposal = (customer: AccountItem) => {
    setSelectedCustomer(JSON.parse(JSON.stringify(customer)));
    setShowCreateProposalModal(true);
  };

  const closeCreateProposalModal = () => {
    setShowCreateProposalModal(false);
  };

  const refreshDetails = (refresh?: boolean) => {
    if (refresh) {
      getAllProposals();
    }
  };

  const [sortBy, setSortBy] = useState<string>("");
  const [asc, setAsc] = useState<boolean>(true);

  const onSortChange = (newSortBy: string, newAsc: boolean) => {
    setSortBy(newSortBy);
    setAsc(newAsc);
    sortData(customersFilteredData, newSortBy, newAsc);
  };

  const sortData = (
    customerData: AccountItem[],
    sortByName: string,
    ascVal: boolean
  ) => {
    const sortedData = (customerData || []).sort((a: any, b: any) => {
      let nameA;
      let nameB;
      if (sortByName === "count") {
        nameA = (a?.proposals || []).length;
        nameB = (b?.proposals || []).length;
      } else if (sortByName === "updatedOn") {
        const aDate = a?.proposals[a?.proposals?.length - 1]?.updatedOn;
        let aUpdatedOn;
        if (aDate) {
          aUpdatedOn = new Date(aDate).getTime();
        }
        nameA = aUpdatedOn ?? 0;
        const bDate = b?.proposals[b?.proposals?.length - 1]?.updatedOn;
        let bUpdatedOn;
        if (bDate) {
          bUpdatedOn = new Date(bDate).getTime();
        }
        nameB = bUpdatedOn ?? 0;
      } else if (sortByName === "isPreProposal") {
        const proposalA = a?.proposals[a?.proposals?.length - 1]?.isPreProposal;
        const proposalB = b?.proposals[b?.proposals?.length - 1]?.isPreProposal;
        if(proposalA === true) {
          nameA = 1;
        } else if(proposalA === false) {
          nameA = 0;
        } else {
          nameA = -1;
        }
        if(proposalB === true) {
          nameB = 1;
        } else if(proposalB === false) {
          nameB = 0;
        } else {
          nameB = -1;
        }
      } else {
        nameA = a[sortByName]?.toString()?.toLowerCase();
        nameB = b[sortByName]?.toString()?.toLowerCase();
      }
      if (ascVal) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    });
    setCustomersFilteredData(sortedData);
  };

  const getFilterValue = () => {
    return {
      name: "",
      saleStatus: "",
      count: "",
      updatedOn: "",
      isPreProposal: "",
    };
  };

  const [filterVal, setFilterVal] = useState<any>(getFilterValue());

  const onFilterChange = (name: string, val: string) => {
    filterVal[name] = val;
    const filtered: AccountItem[] = [];
    customers?.forEach((x) => {
      let proposal;
      const hasProposal = (x?.proposals || [])?.length > 0;

      if (hasProposal) {
        proposal = (x?.proposals || [])[(x?.proposals || []).length - 1];
      }
      if (
        (!filterVal?.name ||
          x.name
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.name?.toString()?.toLowerCase())) &&
        (!filterVal?.saleStatus ||
          x.saleStatus
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.saleStatus?.toString()?.toLowerCase())) &&
        (!filterVal?.count ||
          x.proposals?.length?.toString() === filterVal?.count?.toString()) &&
        (!filterVal?.updatedOn ||
          commonService.getDateString(proposal?.updatedOn || '')
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.updatedOn?.toString()?.toLowerCase())) &&

            (filterVal?.isPreProposal?.toString()?.trim()?.length === 0 ||
              ((proposal?.isPreProposal === true ? 'Pre Proposal': (proposal?.isPreProposal === false ? 'Proposal': ''))
                ?.toString()
                ?.toLowerCase()
                .includes(filterVal?.isPreProposal?.toString()?.toLowerCase())))
      ) {
        filtered.push(x);
      }
    });
    setCustomersFilteredData(filtered);
  };

  const clearFilters = () => {
    setFilterVal(getFilterValue());
    setCustomersFilteredData(customers);
  };

  const gotoAccounts = (accountId: string) => {
    if (accountId && currentAccountId !== accountId) {
      window.open(`/?customerId=${accountId}`, "_blank");
    }
  };

  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AccountItem>();

  const openEditCustomer = (customer: AccountItem) => {
    setSelectedUser(customer);
    setShowCreateCustomerModal(true);
  };

  const closeCreateAccountModal = () => {
    setShowCreateCustomerModal(false);
  };

  const refreshAccount = (refresh?: boolean) => {
    if (refresh) {
      getAccount();
    }
  };

  const getAccount = () => {
    setLoading(true);
    const currentUser =
      JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
    AuthenticationService.getUserById(currentUser?.id)
      .then((response: any) => {
        const result: User = response?.data?.result;
        localStorage.setItem("userDetail", JSON.stringify(result || ""));
        getAllProposals();
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const openUpdateStatus = (customer: AccountItem) => {
    setSelectedUser(customer);
    setShowUpdateStatusModal(true);
  };

  const closeUpdateStatusModal = () => {
    setShowUpdateStatusModal(false);
  };

  return (
    <>
      {showUpdateStatusModal && (
        <AccountStatusModal
          show={showUpdateStatusModal}
          account={selectedUser}
          closeModal={closeUpdateStatusModal}
          refreshDetails={refreshAccount}
        />
      )}
      {showCreateCustomerModal && (
        <AccountModal
          show={showCreateCustomerModal}
          account={selectedUser}
          isEdit={true}
          closeModal={closeCreateAccountModal}
          refreshDetails={refreshAccount}
        />
      )}
      {showProposalDetailModal && (
        <ProposalDetailModal
          show={showProposalDetailModal}
          closeModal={closeProposalModal}
          proposals={selectedProposal}
        />
      )}

      {showCreateProposalModal && (
        <CreateProposalModal
          show={showCreateProposalModal}
          closeModal={closeCreateProposalModal}
          selectedCustomer={selectedCustomer}
          refreshDetails={refreshDetails}
        />
      )}
      <div className="py-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main>
          <div className="divide-y divide-white/5">
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-2 sm:px-6 lg:px-8">
              {loading ? (
                <Pulse show={loading} />
              ) : (
                <>
                  <div className="flex">
                    <button
                    id="proposal-reseller-btn"
                      type="button"
                      disabled={loading}
                      className={commonService.classNames(
                        "mr-4 block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        loading ? "disabled:opacity-75" : ""
                      )}
                      onClick={() => getAccount()}
                    >
                      <Spinner show={loading} />
                      Refresh
                    </button>
                    {customers?.length > customersFilteredData?.length && (
                      <button
                        type="button"
                        className="block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                        onClick={clearFilters}
                      >
                        Clear All Filters
                      </button>
                    )}
                  </div>

                  {customersFilteredData?.length > 0 ? (
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Name"
                              sortName="name"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.name}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                            {/* Name */}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Status"
                              sortName="saleStatus"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.saleStatus}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                            {/* Status */}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Total Proposal"
                              sortName="count"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.count}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                            {/* Name */}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Proposal Date"
                              sortName="updatedOn"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.date}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                            {/* Name */}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Proposal"
                              sortName="isPreProposal"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.type}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                            {/* Name */}
                          </th>
                          
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">View</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {customersFilteredData.map((customer) => (
                          <tr key={customer.id}>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
                              <div className="flex items-center">
                                <a
                                  href="#"
                                  className="flex items-center"
                                  onClick={() => gotoAccounts(customer.id)}
                                >
                                  {customer.name}
                                </a>
                                <InformationCircleIcon
                                  aria-hidden="true"
                                  className="w-4 h-4 ml-1"
                                />
                                <a
                                  href="#"
                                  onClick={() => openEditCustomer(customer)}
                                >
                                  <PencilIcon
                                    aria-hidden="true"
                                    className="w-4 h-4"
                                  />
                                </a>
                                
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => openUpdateStatus(customer)}
                              >
                                {customer.saleStatus}
                              </a>
                            </td>


                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              {(customer?.proposals || []).length}
                            </td>

                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                            {(customer?.proposals || []).length > 0 ? (
                              commonService.getDateString((customer?.proposals || [])[(customer?.proposals || [])?.length -1]?.updatedOn || '')
                            ): <></>
                              }
                            </td>

                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                            {(customer?.proposals || []).length > 0 ? (
                              (customer?.proposals || [])[(customer?.proposals || [])?.length -1]?.isPreProposal ? 'Pre Proposal': 'Proposal'
                            ): <></>
                              }
                            </td>


                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              <a
                                href="#"
                                onClick={() => openProposal(customer)}
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                              >
                                View
                              </a>
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => openCreateProposal(customer)}
                              >
                                Create
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <button
                      type="button"
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                        />
                      </svg>
                      <span className="mt-2 block text-sm font-semibold text-gray-900">
                        Create Account
                      </span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}