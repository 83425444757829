import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
  } from "recharts";
  import commonService from "../../app/service/commonService";
  
  interface Props {
    chartData: any[];
    xAxisKey: string;
    lineKey: string;
    lineKey2?: string;
    refKey1?: string;
    refKey2?: string;
    factor?: number;
    refVal1?: number;
    refVal2?: number;
  }
  
  export function CommonAreaChart(props: Readonly<Props>) {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={props?.chartData || []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={props?.xAxisKey} hide />
          <YAxis domain={[0, ((props?.refVal1 || 0) > (props?.refVal2 || 0) ? props?.refVal1: props?.refVal2) || 0]} />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey={props?.lineKey}
            fill={commonService.coldColors500[0]}
            stroke={commonService.coldColors500[0]}
            activeDot={{ r: 8 }}
          />
          {props?.lineKey2 && (
            <Area
              type="monotone"
              dataKey={props?.lineKey2}
              fill={commonService.coldColors500[1]}
              stroke={commonService.coldColors500[1]}
            />
          )}
          {props?.refKey1 && (
            <ReferenceLine y={props?.refVal1} label={props?.refKey1} stroke="green" />
          )}
          {props?.refKey2 && (
            <ReferenceLine y={props?.refVal2} label={props?.refKey2} stroke="green" />
          )}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
  