import { secureAxios } from "../../http-common";

const proposalUrl = 'portal/Proposal';

const getProposalsByCustomer = async () => {
    return secureAxios.get<AuthenticationResponse>(`${proposalUrl}/proposalsByCustomer`);
};

const updateStatus = async (body: UpdateProposalStatusRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${proposalUrl}/UpdateStatus`, body);
};

const createProposal = async (body: CreateOrUpdateProposalRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${proposalUrl}`, body);
};

const getAllProposals = async () => {
    return secureAxios.get<AuthenticationResponse>(`${proposalUrl}/all`);
};

const ProposalService = {
    getProposalsByCustomer,
    updateStatus,
    createProposal,
    getAllProposals
};

export default ProposalService;