import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import GrantAccessModal from "../initiatives/modal/grantAccessModal";
import InvoiceModal from "../initiatives/modal/invoiceModal";

const timeline = [
  {
    id: 1,
    content: "Upload invoice",
    target: "Float FinOps",
    href: "#",
    date: "Yesterday",
    datetime: "Yesterday",
    icon: "M11.47 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06l-3.22-3.22V16.5a.75.75 0 0 1-1.5 0V4.81L8.03 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5ZM3 15.75a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z",
    iconBackground: "bg-green-500",
  },
  {
    id: 2,
    content: "Validate invoice",
    target: "Float FinOps",
    href: "#",
    date: "Yesterday",
    datetime: "Yesterday",
    icon: "M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z",
    iconBackground: "bg-green-500",
  },
  {
    id: 3,
    content: "Processing findings",
    target: "Float FinOps",
    href: "#",
    date: "Yesterday",
    datetime: "Yesterday",
    icon: "M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z",
    iconBackground: "bg-green-500 animate-spin",
  },
];

export default function PreSaleLoading() {
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );
  const [showGrantAccessModal, setShowGrantAccessModal] =
    useState<boolean>(false);

    const navigate = useNavigate();

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const openInvoiceModal = () => {
    setShowInvoiceModal(true);
  };

  const closeInvoiceModal = () => {
    setShowInvoiceModal(false);
  };

  const refreshDetails = (refresh?: boolean) => {
    console.log("success");
  };

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openGrantAccessModal = () => {
    setShowGrantAccessModal(true);
  };

  const closeGrantAccessModal = () => {
    setShowGrantAccessModal(false);
  };

  return (
    <>
      <InvoiceModal
        show={showInvoiceModal}
        closeModal={closeInvoiceModal}
        refreshDetails={refreshDetails}
      />
      <GrantAccessModal 
      show={showGrantAccessModal}
      closeModal={() => closeGrantAccessModal()}
      subscriptionList={subscriptionList} />
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
            <div className="lg:pr-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  FinOps as a Service
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  A better workflow just arround the corner
                </p>
                <div className="flow-root">
                  <ul className="-mb-8">
                    {timeline.map((event, eventIdx) => (
                      <li key={event.id}>
                        <div className="relative pb-8">
                          {eventIdx !== timeline.length - 1 ? (
                            <span
                              className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={commonService.classNames(
                                  event.iconBackground,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:text-white"
                                )}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d={event.icon}
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {event.content}{" "}
                                  <a
                                    href={event.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {event.target}
                                  </a>
                                </p>
                              </div>
                              <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                <time dateTime={event.datetime}>
                                  {event.date}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">
                            Chris C
                          </span>{" "}
                          commented
                        </div>
                        <time
                          dateTime="07/06/2023"
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          Today
                        </time>
                      </div>
                      <p className="text-sm leading-6 text-gray-500">
                        We are working with your invoiced, we will be done and
                        with results soon
                      </p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[44rem] md:-ml-4 lg:ml-0"
              width={2432}
              height={1442}
            />
          </div>
          <div className="mt-8">
            <a
              href="#"
              className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={openInvoiceModal}
            >
              Import my Invoice
            </a>
            <a
              href="#"
              className="text-indigo-600 hover:text-indigo-900 ml-12"
              onClick={() => openGrantAccessModal()}
            >
              Skip step and Grant Acces
            </a>
          </div>
          <div className="mt-8">
            <a
              href="#"
              onClick={() => commonService.parseNavigate("/newAccountPotential", navigate)}
              className="inline-flex rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Take me to my birds eye view
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
