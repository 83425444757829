import { secureAxios } from "../../http-common";

const cloudResourceUrl = 'portal/CloudResource';
const cloudResourceCompute = 'portal/CloudResourceCompute';

const getAllSubscriptions = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/Subscriptions`);
};

const getVM = async (customerId: string, resourceId: string, lookBackPeriodDays = 30) => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceCompute}/GetVm?customerId=${customerId}&resourceId=${resourceId}&lookBackPeriodDays=${lookBackPeriodDays}`);
};

const getFinOpsSummaryVMs = async (customerId: string, lookBackPeriodDays = 30) => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceCompute}/GetFinOpsSummaryVMs?customerId=${customerId}&lookBackPeriodDays=${lookBackPeriodDays}`);
};

const getResources = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/GetResources`);
};

const listTenantBudget = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/ListTenantBudget`);
};

const createTenantBudget = async (body: CreateOrUpdateTenantBudgetRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceUrl}/TenantBudget`, body);
};

const updateTenantBudget = async (body: CreateOrUpdateTenantBudgetRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${cloudResourceUrl}/TenantBudget`, body);
};

const getListAvailableSizes = async (resourceId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceCompute}/ListAvailableSizes?resourceId=${resourceId}`);
};

const getVmRightSizeComparison = async (body: AzureVmRightSizeComparisonRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceCompute}/GetVmRightSizeComparison`, body);
};

const getVmRightSizeInfo = async (body: AzureVmRightSizeInfoRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceCompute}/GetVmRightSizeInfo`, body);
};

const getListUnattachedDisk = async (body: AzureUnattachedDiskRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceUrl}/ListUnattachedDisk`, body);
};

const getListStorageAccounts = async (body: AzureStorageAccountsRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceUrl}/ListStorageAccounts`, body);
}

const getSkuInfo = async (size: string, location: string) => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceCompute}/SkuInfo?size=${size}&location=${location}`);
};

const getListUnbindedSubscriptions = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/ListUnbindedSubscriptions`);
}

const getGraphQueryResult = async () => {
    return secureAxios.get<AuthenticationResponse>(`${cloudResourceUrl}/GraphQueryResult`);
}

const getSnapshot = async (request: SnapshotRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${cloudResourceUrl}/GetSnapshot`, request);
}

const CloudResourceService = {
    getAllSubscriptions,
    getVM,
    getResources,
    getFinOpsSummaryVMs,
    listTenantBudget,
    createTenantBudget,
    updateTenantBudget,
    getListAvailableSizes,
    getVmRightSizeComparison,
    getVmRightSizeInfo,
    getListUnattachedDisk,
    getListStorageAccounts,
    getSkuInfo,
    getListUnbindedSubscriptions,
    getGraphQueryResult,
    getSnapshot
};

export default CloudResourceService;