import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
import { TabsView } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal?: any;
  resources: OpportunityPlanResourceItem[];
}

interface TaskView {
  template: OpportunityPlanResourceTaskTemplateItem;
  resources: OpportunityPlanResourceItem[];
}

const tabs = [
  { name: "Task", href: "#", current: true },
  { name: "Resource", href: "#", current: false },
];

export default function TaskViewModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [currentTabSelection, setCurrentTabSelection] = useState<string>(
    tabs[0]?.name
  );
  const [taskList, setTaskList] = useState<TaskView[]>([]);
  const [selectedTask, setSelectedTask] = useState<TaskView | object>();

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    setTaskList([]);
    setSelectedTask({});
    setCurrentTabSelection(tabs[0]?.name);
    groupTaskList();
  };

  const groupTaskList = () => {
    const tasks: TaskView[] = [];
    (props.resources || []).forEach((x) => {
      (x.opportunityPlanResourceTask || []).forEach((o) => {
        const i = tasks.findIndex(
          (t) => t.template.id === o.opportunityPlanResourceTaskTemplateId
        );
        if (i === -1) {
          tasks.push({
            template: o.opportunityPlanResourceTaskTemplate,
            resources: [x],
          });
        } else {
          tasks[i].resources.push(x);
        }
      });
    });
    const sortedTasks = tasks.sort(
      (a: TaskView, b: TaskView) =>
        (b.resources?.length ?? 0) - (a.resources?.length ?? 0)
    );
    setTaskList(sortedTasks);
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onNavigateResource = (task: TaskView) => {
    setSelectedTask(task);
    setCurrentTabSelection("Resource");
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Task View
            </Dialog.Title>
            <div className="mt-2">
              <TabsView tabs={tabs} currentTabSelection={currentTabSelection} setCurrentTabSelection={setCurrentTabSelection} />

              <div
                className={commonService.classNames(
                  currentTabSelection !== "Task" ? "hidden" : ""
                )}
              >
                <div className="my-2">
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Resource Count
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                        >
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {taskList.map((task) => (
                        <tr key={task.template.id}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
                            {task.template.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {task.resources?.length ?? 0}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900 mr-3"
                              onClick={() => onNavigateResource(task)}
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className={commonService.classNames(
                  currentTabSelection !== "Resource" ? "hidden" : "",
                  "mt-2"
                )}
              >
                <div className="my-2">
                  {((selectedTask as TaskView)?.resources || [])?.length > 0 ? (
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Template
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            CSP
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Subscription
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Resource Group
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {((selectedTask as TaskView)?.resources || []).map(
                          (resource) => (
                            <tr key={resource.id}>
                              <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
                                {resource?.dailyData?.data[0]?.resourceName ??
                                  resource?.cspResourceId?.split("/")[8]}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {(selectedTask as TaskView)?.template?.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {
                                  resource?.opportunityPlanResourceTask[0]
                                    ?.opportunityPlanResourceTaskTemplate?.csp
                                }
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {resource?.cspResourceId?.split("/")[2]}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {resource?.cspResourceId?.split("/")[4]}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <button
                      type="button"
                      className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                      <span className="mt-2 block text-sm font-semibold text-gray-900">
                        Please select a Task.
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
