import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { CommonLineChart } from "../../common/lineChart";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  data: any;
}

export default function StorageAccountModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [ingressChartData, setIngressChartData] = useState<
    Array<{ date: string; Ingress: number }>
  >([]);
  const [egressChartData, setEgressChartData] = useState<
    Array<{ date: string; Egress: number }>
  >([]);
  const [latencyChartData, setLatencyChartData] = useState<
    Array<{ date: string; Latency: number }>
  >([]);
  const [transactionsChartData, setTransactionsChartData] = useState<
    Array<{ date: string; Transactions: number }>
  >([]);
  const rangeList: string[] = ["minimum", "average", "maximum"];
  const [selectedRange, setSelectedRange] = useState<string>(rangeList[0]);

  const [storageData, setStorageData] = useState<any>(props?.data || {});

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    setSelectedRange(rangeList[0]);
    setStorageData(props?.data || {});
    setChart(props?.data || {}, rangeList[0]);
  };

  const setChart = (result: any, range: string) => {
    const metrics = result?.metrics || [];
    metrics.forEach((x: any) => {
      if (x.name === "Ingress") {
        setIngressChartData(commonService.getChartData(x, range, "Ingress"));
      } else if (x.name === "Egress") {
        setEgressChartData(commonService.getChartData(x, range, "Egress"));
      } else if (x.name === "SuccessE2ELatency") {
        setLatencyChartData(
          commonService.getChartData(x, range, "SuccessE2ELatency")
        );
      } else if (x.name === "Transactions") {
        setTransactionsChartData(
          commonService.getChartData(x, range, "Transactions")
        );
      }
    });
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeRange = (range: string) => {
    setSelectedRange(range);
    setChart(storageData, range);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              VM Details
            </Dialog.Title>
            <div className="mt-2">
                <div>
                  <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                    <li
                      key="1"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          General
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Name</dt>
                          <dd className="text-gray-700">
                            {storageData?.resourceData?.name}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Location</dt>
                          <dd className="text-gray-700">
                            {
                              storageData?.resourceData?.location
                                ?.displayName
                            }
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="2"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Tags
                        </div>
                      </div>

                      <p className="gap-x-4 px-6 py-4">
                        {Object.keys(
                          storageData?.resourceData?.tags || {}
                        ).map((key: string) => (
                          <span className="text-sm leading-6 mr-2" key={key}>
                            <span className="mb-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {`${key} : ${
                                (storageData?.resourceData?.tags || {})[
                                  key
                                ]
                              }`}
                            </span>
                          </span>
                        ))}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <div>
                    <label
                      htmlFor="storage-timeZone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Range
                    </label>
                    <div className="mt-1 mb-1">
                      <select
                        id="storage-timeZone"
                        name="timezone"
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                        value={selectedRange}
                        onChange={(e) => onChangeRange(e?.target?.value)}
                      >
                        {rangeList.map((range: string) => (
                          <option value={range} key={range}>
                            {range}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Ingress
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={ingressChartData}
                            xAxisKey="date"
                            lineKey="Ingress"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Egress
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={egressChartData}
                            xAxisKey="date"
                            lineKey="Egress"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            SuccessE2ELatency
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={latencyChartData}
                            xAxisKey="date"
                            lineKey="SuccessE2ELatency"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Transactions
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={transactionsChartData}
                            xAxisKey="date"
                            lineKey="Transactions"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
