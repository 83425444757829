import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import ProposalService from "../../../app/service/proposalService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  refreshDetails: (refresh?: boolean) => void;
  selectedCustomer: AccountSalesProposalItem | undefined;
}

const errorState: any = {
  displaySavingsPercentage: { hasError: false, required: false },
  currentInvoiceTotal: { hasError: false, required: false },
  commitmentsCurrent: { hasError: false, required: false },
  commitmentsSavings: { hasError: false, required: false },
  licensingCurrent: { hasError: false, required: false },
  licensingSavings: { hasError: false, required: false },
  optimizationSavings: { hasError: false, required: false },
  wasteSavings: { hasError: false, required: false },
  rateOptimizationSavings: { hasError: false, required: false },
  resourceCount: { hasError: false, required: false },
  securityFindings: { hasError: false, required: false },
  governanceFindings: { hasError: false, required: false },
  guaranteeSavings: { hasError: false, required: false },
  status: { hasError: false, required: false },
  notes: { hasError: false, required: true },
  decisionComments: { hasError: false, required: true },
  plan: { hasError: false, required: true },
  term: { hasError: false, required: true },
  paymentTerm: { hasError: false, required: true },
  serviceCost: { hasError: false, required: false },
};

export default function CreateProposalModal(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if(props.show) {
      init();
      checkError();
    }
  }, [props.show]);

  const init = () => {
    setProposal(initProposal());
  };

  const initProposal = (): CreateOrUpdateProposalRequest => {
    const proposal: any = {
      customerId: props?.selectedCustomer?.id ?? "",
      displaySavingsPercentage: 0,
      currentInvoiceTotal: 0,
      commitmentsCurrent: 0,
      commitmentsSavings: 0,
      licensingCurrent: 0,
      licensingSavings: 0,
      optimizationSavings: 0,
      wasteSavings: 0,
      rateOptimizationSavings: 0,
      resourceCount: 0,
      securityFindings: 0,
      governanceFindings: 0,
      guaranteeSavings: 0,
      status: null,
      notes: "",
      decisionComments: "",
      plan: "",
      term: "",
      paymentTerm: "",
      serviceCost: 0,
      isPreProposal: true,
    };

    return proposal;
  };

  const [proposal, setProposal] = useState<CreateOrUpdateProposalRequest | any>(
    initProposal()
  );

  useEffect(() => {
    checkError();
  }, [proposal]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(proposal));
    for (const key of Object.keys(errorState) || []) {
      onInputChange(key, model[key] || "");
    }
  };

  const createProposal = () => {
    onCheckError();
    checkError();
    if (!loading && !hasError) {
      console.log(proposal);
      setLoading(true);
      ProposalService.createProposal(proposal)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            props?.refreshDetails(true);
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = field?.required && !value;
    const model = JSON.parse(JSON.stringify(proposal));
    model[fieldName] = value;
    setProposal(model);
    error[fieldName] = field;
  };

  const onCheckboxChange = (checked: boolean) => {
    const model = JSON.parse(JSON.stringify(proposal));
    model.isPreProposal = checked;
    setProposal(model);
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (errorState[key]?.required && !proposal[key]) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 mb-10 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Create Proposal
            </Dialog.Title>
            <div className="mt-2">
              <div>
                <form className="space-y-6">
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="displaySavingsPercentage"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Display Savings Percentage
                      </label>
                      <div className="mt-2">
                        <input
                          id="displaySavingsPercentage"
                          name="displaySavingsPercentage"
                          type="text"
                          value={proposal.displaySavingsPercentage}
                          onChange={(e) =>
                            onInputChange(
                              "displaySavingsPercentage",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="currentInvoiceTotal"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Current Invoice Total
                      </label>
                      <div className="mt-2">
                        <input
                          id="currentInvoiceTotal"
                          name="currentInvoiceTotal"
                          type="text"
                          value={proposal.currentInvoiceTotal}
                          onChange={(e) =>
                            onInputChange(
                              "currentInvoiceTotal",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="commitmentsCurrent"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Commitments Current
                      </label>
                      <div className="mt-2">
                        <input
                          id="commitmentsCurrent"
                          name="commitmentsCurrent"
                          type="text"
                          required
                          value={proposal.commitmentsCurrent}
                          onChange={(e) =>
                            onInputChange(
                              "commitmentsCurrent",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="commitmentsSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Commitments Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="commitmentsSavings"
                          name="commitmentsSavings"
                          type="text"
                          value={proposal.commitmentsSavings}
                          onChange={(e) =>
                            onInputChange(
                              "commitmentsSavings",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="licensingCurrent"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Licensing Current
                      </label>
                      <div className="mt-2">
                        <input
                          id="licensingCurrent"
                          name="licensingCurrent"
                          type="text"
                          value={proposal.licensingCurrent}
                          onChange={(e) =>
                            onInputChange("licensingCurrent", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="licensingSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Licensing Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="licensingSavings"
                          name="licensingSavings"
                          type="text"
                          value={proposal.licensingSavings}
                          onChange={(e) =>
                            onInputChange("licensingSavings", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="optimizationSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Optimization Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="optimizationSavings"
                          name="optimizationSavings"
                          type="text"
                          value={proposal.optimizationSavings}
                          onChange={(e) =>
                            onInputChange(
                              "optimizationSavings",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="wasteSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Waste Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="wasteSavings"
                          name="wasteSavings"
                          type="text"
                          value={proposal.wasteSavings}
                          onChange={(e) =>
                            onInputChange("wasteSavings", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="rateOptimizationSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Rate Optimization Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="rateOptimizationSavings"
                          name="rateOptimizationSavings"
                          type="text"
                          value={proposal.rateOptimizationSavings}
                          onChange={(e) =>
                            onInputChange(
                              "rateOptimizationSavings",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="resourceCount"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Resource Count
                      </label>
                      <div className="mt-2">
                        <input
                          id="resourceCount"
                          name="resourceCount"
                          type="text"
                          required
                          value={proposal.resourceCount}
                          onChange={(e) =>
                            onInputChange("resourceCount", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="securityFindings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Security Findings
                      </label>
                      <div className="mt-2">
                        <input
                          id="securityFindings"
                          name="securityFindings"
                          type="text"
                          value={proposal.securityFindings}
                          onChange={(e) =>
                            onInputChange("securityFindings", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="governanceFindings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Governance Findings
                      </label>
                      <div className="mt-2">
                        <input
                          id="governanceFindings"
                          name="governanceFindings"
                          type="text"
                          value={proposal.governanceFindings}
                          onChange={(e) =>
                            onInputChange(
                              "governanceFindings",
                              e?.target?.value
                            )
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="guaranteeSavings"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Guarantee Savings
                      </label>
                      <div className="mt-2">
                        <input
                          id="guaranteeSavings"
                          name="guaranteeSavings"
                          type="text"
                          required
                          value={proposal.guaranteeSavings}
                          onChange={(e) =>
                            onInputChange("guaranteeSavings", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="notes"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Notes
                      </label>
                      <div className="mt-2">
                        <input
                          id="notes"
                          name="notes"
                          type="text"
                          required
                          value={proposal.notes}
                          onChange={(e) =>
                            onInputChange("notes", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.notes?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.notes?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Notes is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="decisionComments"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Decision Comments
                      </label>
                      <div className="mt-2">
                        <input
                          id="decisionComments"
                          name="decisionComments"
                          type="text"
                          required
                          value={proposal.decisionComments}
                          onChange={(e) =>
                            onInputChange("decisionComments", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.decisionComments?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.decisionComments?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Decision Comments is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="plan"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Plan
                      </label>
                      <div className="mt-2">
                        <input
                          id="plan"
                          name="plan"
                          type="text"
                          required
                          value={proposal.plan}
                          onChange={(e) =>
                            onInputChange("plan", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.plan?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.plan?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Plan is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="term"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Term
                      </label>
                      <div className="mt-2">
                        <input
                          id="term"
                          name="term"
                          type="text"
                          required
                          value={proposal.term}
                          onChange={(e) =>
                            onInputChange("term", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.term?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.term?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Term is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="paymentTerm"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Payment Term
                      </label>
                      <div className="mt-2">
                        <input
                          id="paymentTerm"
                          name="paymentTerm"
                          type="text"
                          required
                          value={proposal.paymentTerm}
                          onChange={(e) =>
                            onInputChange("paymentTerm", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.paymentTerm?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.paymentTerm?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Payment Term is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="serviceCost"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Service Cost
                      </label>
                      <div className="mt-2">
                        <input
                          id="serviceCost"
                          name="serviceCost"
                          type="text"
                          value={proposal.serviceCost}
                          onChange={(e) =>
                            onInputChange("serviceCost", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="isPreProposal"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Is Pre-Proposal?
                      </label>
                      <div className="mt-2">
                        <input
                          id="isPreProposal"
                          name="isPreProposal"
                          type="checkbox"
                          checked={proposal.isPreProposal}
                          onChange={(e) => onCheckboxChange(e.target.checked)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3 sm:grid-cols-2">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createProposal()}
          >
            <Spinner show={loading} />
            Create
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
