import { Dialog } from "@headlessui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Spinner from "../../../app/spinner/spinner";
import ReportService from "../../../app/service/reportService";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal?: any;
  refreshDetails?: any;
}

const errorState: any = {
  name: { hasError: false, required: true },
  year: { hasError: false, required: true },
  month: { hasError: false, required: true },
  file: { hasError: false, required: true },
};

export default function InvoiceModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const [invoiceName, setInvoiceName] = useState<string>();
  const [invoiceYear, setInvoiceYear] = useState<number>(2024);
  const [invoiceMonth, setInvoiceMonth] = useState<number>(2);
  const [invoiceFile, setInvoiceFile] = useState<any>();
  const [subscriptionId, setSubscriptionId] = useState<any>();

  const cancelButtonRef = useRef(null);

  const [yearList, setYearList] = useState<number[]>();
  const [monthList, setMonthList] = useState<number[]>();
  const [maxYear, setMaxYear] = useState<number>(new Date().getFullYear());
  const [maxMonth, setMaxMonth] = useState<number>(12);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    setOpen(props.show);
    initInvoice();
  }, [props.show]);

  const initInvoice = () => {
    setInvoiceName("");
    setInvoiceFile(null);
    resetErrorState();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    setMaxYear(currentYear);
    setMaxMonth(currentMonth);
    setInvoiceYear(currentYear);
    setInvoiceMonth(currentMonth);
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    setYearList(years);
    resetMonthList(maxYear);
  };

  const resetErrorState = () => {
    Object.keys(errorState).forEach((key) => {
      errorState[key].hasError = false;
    });
    setHasError(false);
  };

  const resetMonthList = (year: number) => {
    if (maxYear == year) {
      const months = Array.from({ length: maxMonth }, (_, index) => index + 1);
      setMonthList(months);
    } else {
      const months = Array.from({ length: 12 }, (_, index) => index + 1);
      setMonthList(months);
    }
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onInvoiceNameChange = (value: string) => {
    setInvoiceName(value);
    checkError();
  };

  const onSubscriptionIdChange = (value: string) => {
    setSubscriptionId(value);
    checkError();
  };

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const onChangeYear = (year: number) => {
    setInvoiceYear(year);
    resetMonthList(year);
  };

  const onChangeMonth = (month: number) => {
    setInvoiceMonth(month);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setInvoiceFile(e.target.files[0]);
    errorState["file"].hasError = false;
    setError(errorState);
    setHasError(false);
  };

  const checkError = () => {
    if (!invoiceName) {
      errorState["name"].hasError = true;
      setError(errorState);
      setHasError(true);
      return;
    } else {
      errorState["name"].hasError = false;
      setError(errorState);
      setHasError(false);
    }

    if (!invoiceFile) {
      errorState["file"].hasError = true;
      setError(errorState);
      setHasError(true);
      return;
    } else {
      errorState["file"].hasError = false;
      setError(errorState);
      setHasError(false);
    }
    resetErrorState();
  };

  const createInvoice = () => {
    checkError();
    if (!hasError && invoiceName) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", invoiceFile);
      ReportService.uploadInvoice(
        formData,
        invoiceYear,
        invoiceMonth,
        invoiceName,
        subscriptionId
      )
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoading(false);
          if (response?.data?.isSuccess) {
            props.refreshDetails(true);
            closeModal();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative z-500 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 mb-10 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Add Invoice
            </Dialog.Title>
            <div className="mt-2">
              <div>
                <form className="space-y-6">
                  <div className="mt-6 grid grid-cols-3 gap-x-6 gap-y-8 z-100">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900"
                      htmlFor="invoiceName">
                        Name
                      </label>
                      <dd className="mt-2">
                          <div className="mt-1">
                            <input
                              id="invoiceName"
                              name="invoiceName"
                              type="text"
                              required
                              value={invoiceName}
                              onChange={(e) =>
                                onInvoiceNameChange(e?.target?.value)
                              }
                              className={classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                error?.name?.hasError
                                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                  : ""
                              )}
                            />
                          </div>
                      </dd>

                      {error?.name?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Name is required.
                      </p>
                      )}
                    </div>

                    <div className="col-span-1">
                      <label
                        htmlFor="invoiceYear"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Invoice Year
                      </label>
                      <div className="mt-2">
                        <select
                          id="invoiceYear"
                          name="invoiceYear"
                          value={invoiceYear}
                          onChange={(e) => onChangeYear(+e?.target?.value)}
                          className={classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        >
                          {yearList?.map((c: number) => (
                            <option value={c} key={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <label
                        htmlFor="invoiceMonth"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Invoice Month
                      </label>
                      <div className="mt-2">
                        <select
                          id="invoiceMonth"
                          name="invoiceMonth"
                          value={invoiceMonth}
                          onChange={(e) => onChangeMonth(+e?.target?.value)}
                          className={classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        >
                          {monthList?.map((c: number) => (
                            <option value={c} key={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <dt className="block text-sm font-medium leading-6 text-gray-900">
                        Subscription ID
                      </dt>
                      <dd className="mt-2">
                          <div className="mt-1">
                            <input
                              id="subscriptionId"
                              name="subscriptionId"
                              type="text"
                              required
                              value={subscriptionId}
                              onChange={(e) =>
                                onSubscriptionIdChange(e?.target?.value)
                              }
                              className={classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                error?.name?.hasError
                                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                  : ""
                              )}
                            />
                          </div>
                      </dd>

                      {error?.name?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Subscription ID is required.
                      </p>
                      )}
                    </div>

                    <div className="col-span-1">
                      <label
                        htmlFor="startOn"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Select Invoice File
                      </label>
                      <div className="mt-2">
                        <button
                          type="button"
                          className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={handleUploadClick}
                        >
                          {invoiceFile ? `${invoiceFile.name}` : "Change file"}
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-500">
                          csv file expected
                        </p>
                        <input
                          type="file"
                          ref={inputRef}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      {error?.file?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Invoice File is required.
                      </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={!invoiceName || !invoiceFile}
            className={classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createInvoice()}
          >
            <Spinner show={loading} />
            Upload
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
