import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import commonService from "../app/service/commonService";

export function RedirectComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        if(commonService.redirectPath) {
            commonService.parseNavigate(commonService.redirectPath, navigate);
        }
    }, []);

    return (
        <></>
    )
}