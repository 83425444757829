import { secureAxios } from "../../http-common";

const opportunityUrl = 'portal/Opportunity';

const getAllProject = async (accountId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/projects/${accountId}`);
};

const createProject = async (body: CreateOrUpdateProjectRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/project`, body)
};

const updateProject = async (body: CreateOrUpdateProjectRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/project`, body);
};

const deleteProject = async (planId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/project/${planId}`);
};

const getAllOpportunityPlan = async (projectId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/plan/list/${projectId}`);
};

const createOpportunityPlan = async (body: CreateOrUpdateOpportunityPlanRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/plan`, body);
};

const updateOpportunityPlan = async (body: CreateOrUpdateOpportunityPlanRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/plan`, body);
};

const getOpportunityPlan = async (planId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/plan/${planId}`);
};

const createOpportunityPlanStep = async (body: CreateOrUpdateOpportunityPlanStepRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/planStep`, body);
};

const updateOpportunityPlanStep = async (body: CreateOrUpdateOpportunityPlanStepRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/planStep`, body);
};

const deleteOpportunityPlan = async (planId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/plan/${planId}`);
};

const deleteOpportunityPlanStep = async (planStepId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/planStep/${planStepId}`);
};

const getPlanResourceTaskTemplate = async () => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/planResourceTaskTemplate`);
};

const createOpportunityPlanResource = async (body: CreateOrUpdateOpportunityPlanResourceRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/planResource`, body);
};

const updateOpportunityPlanResource = async (body: CreateOrUpdateOpportunityPlanResourceRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/planResource`, body);
};

const deleteOpportunityPlanResource = async (planResourceId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/planResource/${planResourceId}`);
};

const createOpportunityPlanResourceTask = async (body: CreateOrUpdateOpportunityPlanResourceTaskRequest[]) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/planResourceTask`, body);
}

const updateOpportunityPlanResourceTask = async (body: CreateOrUpdateOpportunityPlanResourceTaskRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/planResourceTask`, body);
}

const deleteOpportunityPlanResourceTask = async (planResourceTaskId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/planResourceTask/${planResourceTaskId}`);
}

const getOpportunityPlanContacts = async (planId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/planContacts/list/${planId}`);
};

const createOpportunityPlanContacts = async (body: CreateOrUpdateOpportunityPlanContactsRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/planContacts`, body);
};

const updateOpportunityPlanContacts = async (body: CreateOrUpdateOpportunityPlanContactsRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${opportunityUrl}/planContacts`, body);
};

const deleteOpportunityPlanContacts = async (planContactsId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${opportunityUrl}/planContacts/${planContactsId}`);
};

const getOpportunityPlanActivity = async (planId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/planActivity/list/${planId}`);
};

const createOpportunityPlanActivity = async (body: CreateOrUpdateOpportunityPlanActivityRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${opportunityUrl}/planActivity`, body);
}

const getOpportunityPlanStep = async (planStepId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${opportunityUrl}/planStep/${planStepId}`);
};

const OpportunityService = {
    getAllProject,
    createProject,
    updateProject,
    deleteProject,
    getAllOpportunityPlan,
    createOpportunityPlan,
    updateOpportunityPlan,
    getOpportunityPlan,
    createOpportunityPlanStep,
    updateOpportunityPlanStep,
    deleteOpportunityPlan,
    deleteOpportunityPlanStep,
    getPlanResourceTaskTemplate,
    createOpportunityPlanResource,
    updateOpportunityPlanResource,
    createOpportunityPlanResourceTask,
    createOpportunityPlanContacts,
    updateOpportunityPlanContacts,
    deleteOpportunityPlanContacts,
    createOpportunityPlanActivity,
    getOpportunityPlanActivity,
    getOpportunityPlanStep,
    updateOpportunityPlanResourceTask,
    getOpportunityPlanContacts,
    deleteOpportunityPlanResourceTask,
    deleteOpportunityPlanResource
};

export default OpportunityService;