import { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { InitiativeDetailsHistory } from "../../components/initiatives/details/initiativeDetailsHistory";
import { InitiativeDetailSummaryCard } from "../../components/initiatives/details/initiativeDetailSummaryCard";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OpportunityService from "../service/opportunityService";
import Pulse from "../pulse/pulse";
import PlanStepModal from "../../components/initiatives/modal/stepModal";
import commonService from "../service/commonService";
import ConfirmationModal from "../../components/reports/modal/confirmationModal";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const deletePlanModal = {
  title: "Delete Plan Step",
  message: "Are you sure you want to delete the Plan Step",
};

export default function InitiativesDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<OpportunityPlanItem>();
  const [activities, setActivities] = useState<OpportunityPlanActivityItem[]>([]);
  const navigate = useNavigate();
  let interval: NodeJS.Timer;

  useEffect(() => {
    getOpportunityPlan();
    commonService.getAllUsers();
    initLoop();
    return () => {
      // Anything in here is fired on component unmount.
      clearLoop();
  }
  }, []);

  const getOpportunityPlan = () => {
    if (id) {
      setLoading(true);
      commonService.planId = id;
      OpportunityService.getOpportunityPlan(id || "")
        .then((response: any) => {
          setLoading(false);
          const result = parseResult(response?.data?.result || {});
          setPlan(result);
          setPlanDescription(result.description);
          result.opportunityPlanActivities = parseActivity(result.opportunityPlanActivities || []);
          setActivities(result.opportunityPlanActivities)
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const parseResult = (result: any) => {
    let totalTaskCount = 0;
    let completedTaskCount = 0;
    (result?.opportunityPlanStep || []).forEach(
      (opportunityPlanStepItem: OpportunityPlanStepItem) => {
        let totalHours = 0;
        let totalValue = 0;
        opportunityPlanStepItem.opportunityPlanResources.forEach(
          (opportunityPlanResourceItem) => {
            opportunityPlanResourceItem.opportunityPlanResourceTask.forEach(
              (opportunityPlanResourceTaskItem) => {
                totalHours += opportunityPlanResourceTaskItem.hours;
                totalValue += opportunityPlanResourceTaskItem.value;
                totalTaskCount++;
                if(!!opportunityPlanResourceTaskItem?.completedBy || !!opportunityPlanResourceTaskItem?.completedOn) {
                  completedTaskCount++;
                }
              }
            );
          }
        );
        opportunityPlanStepItem.hours = totalHours || 0;
        opportunityPlanStepItem.value = totalValue || 0;
        if(totalTaskCount > 0 && completedTaskCount === totalTaskCount) {
          opportunityPlanStepItem.status = 'Completed';
        } else if(totalTaskCount > 0 && completedTaskCount > 0) {
          opportunityPlanStepItem.status = 'InProgress';
        } else {
          opportunityPlanStepItem.status = 'Created';
        }
      }
    );
    return result;
  }

  const parseActivity = (data: OpportunityPlanActivityItem[]) => {
    (data || []).forEach((x: OpportunityPlanActivityItem) => {
      x.timeAgo = commonService.timeAgo(x.createdOn);
    });
    const orderedData: OpportunityPlanActivityItem[] = (data || []).sort((a, b) => {
      const aDate = new Date(a.createdOn + "Z");
      const bDate = new Date(b.createdOn + "Z");
        if (aDate > bDate) {
          return 1
        } else if (aDate < bDate) {
          return -1
        } else {
          return 0
        }
    });
    return orderedData || [];
  }

  const initLoop = () => {
    interval = setInterval(() => {
      getOpportunityPlanActivity();
  }, 1000 * 60);
  }

const clearLoop = () => {
  clearInterval(interval);
}

  const getOpportunityPlanActivity = () => {
    if (id) {
      OpportunityService.getOpportunityPlanActivity(id || "")
        .then((response: any) => {
          const result = response?.data?.result || [];
          setActivities(parseActivity(result));
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
        });
    }
  };

  const [showDetailModal, setShowDetailModal] = useState(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [selectedStep, setSelectedStep] = useState<OpportunityPlanStepItem>();

  const [showDeleteStepModal, setShowDeleteStepModal] =
    useState<boolean>(false);
  const [loadingDeleteStep, setLoadingDeleteStep] = useState<boolean>(false);
  const [selectedStepForDelete, setSelectedStepForDelete] = useState<string>();
  const [deleteMessage, setDeleteMessage] = useState<string>("");

  const closeDetailModal = (refresh?: boolean) => {
    setShowDetailModal(false);
    if (refresh) {
      getOpportunityPlan();
    }
  };

  const openStepModal = () => {
    setIsEdit(false);
    openDetailModal();
  };

  const openDetailModal = () => {
    setShowDetailModal(true);
  };

  const editStep = (id: string | undefined) => {
    const currentStep: OpportunityPlanStepItem | undefined = (
      plan?.opportunityPlanStep || []
    ).find((x) => x.id === id);
    if (currentStep) {
      setSelectedStep(currentStep);
      setIsEdit(true);
      openDetailModal();
    }
  };

  const closeDeleteStepModal = () => {
    setShowDeleteStepModal(false);
  };

  const openDeleteStepModal = (e: any, step: OpportunityPlanStepItem) => {
    e?.preventDefault();
    setDeleteMessage(`${deletePlanModal.message}: ${step.name} ?`);
    setSelectedStepForDelete(step.id);
    setShowDeleteStepModal(true);
  };

  const onDeleteStep = () => {
    if (selectedStepForDelete) {
      setLoadingDeleteStep(true);
      OpportunityService.deleteOpportunityPlanStep(selectedStepForDelete)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeleteStep(false);
          if (response?.data?.isSuccess) {
            closeDeleteStepModal();
            getOpportunityPlan();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoadingDeleteStep(false);
        });
    }
  };

  const refreshDetails = (refresh?: boolean) => {
    if (refresh) {
      getOpportunityPlan();
    }
  };

  const [planDescription, setPlanDescription] = useState(plan?.description);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [originalText, setOriginalText] = useState(plan?.description);

  const handleEditClick = () => {
    setIsEditingDescription(true);
    setOriginalText(planDescription);
  };

  const handleCancelClick = () => {
    setIsEditingDescription(false);
    setPlanDescription(originalText);
  };

  const handleSaveClick = () => {
    setIsEditingDescription(false);
    onSavePlanDescription();
  };

  const handleChange = (event: any) => {
    setPlanDescription(event?.target?.value);
  };

  const onSavePlanDescription = () => {
    if(plan){
      const body: CreateOrUpdateOpportunityPlanRequest = {
        id: plan.id,
        accountId: plan.accountId,
        projectId: plan.projectId,
        planCode: plan.planCode,
        name: plan.name,
        description: planDescription || "",
        value: plan.value,
        startOn: plan.startOn,
        dueOn: plan.dueOn,
        status: plan.status || ""
      };
      OpportunityService.updateOpportunityPlan(body)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            getOpportunityPlan();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
        });
    }
  };

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const printPlan = () => {
    if (!pdfLoading) {
      setPdfLoading(true);
      window.scrollTo(0, 0);
      const data: any = document.getElementById("print_id");
      setTimeout(() => {
        html2canvas(data).then((canvas) => {
          const imgData = canvas.toDataURL("image/JPEG");
          const pdf = new jsPDF({
            orientation: "landscape",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("plan.pdf");
          setPdfLoading(false);
        });
      }, 2000);
    }
  };

  return (
    <>
      {showDetailModal && <PlanStepModal
        show={showDetailModal}
        closeModal={closeDetailModal}
        plan={plan}
        isEdit={isEdit}
        step={selectedStep}
      />}
      <ConfirmationModal
        show={showDeleteStepModal}
        title={deletePlanModal.title}
        message={deleteMessage}
        loading={loadingDeleteStep}
        closeModal={() => closeDeleteStepModal()}
        save={() => onDeleteStep()}
      />
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          {loading ? (
            <Pulse show={loading} />
          ) : (
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-3 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3"
            id="print_id">
              {/* Invoice summary */}
              <InitiativeDetailSummaryCard
                plan={plan}
                refreshDetails={refreshDetails}
                printPlan={printPlan}
                pdfLoading={pdfLoading}
              />

              {/* Invoice */}

              <div className={commonService.classNames("-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-start-1 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16", 
              pdfLoading ? "lg:col-end-4": "lg:col-end-3")}>
                <h2 className="text-base font-semibold leading-6 text-gray-900">
                  Plan
                </h2>
                <h4 className="text-base font-semibold leading-6 text-gray-300">
                  Plan Code: {plan?.planCode}
                </h4>

                <h2 className="text-base font-semibold leading-6 text-gray-900">
                  {plan?.name}
                </h2>
                
                <div>
                  {isEditingDescription ? (
                    <div className="space-y-2">
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={planDescription}
                        onChange={handleChange}
                        rows={4}
                      />
                      <div className="flex space-x-4">
                        <button
                          className="px-3 py-2 bg-indigo-500 text-white rounded-md text-sm hover:bg-indigo-600"
                          onClick={handleSaveClick}
                        >
                          Save
                        </button>
                        <button
                          className="px-3 py-2 border border-gray-300 rounded-md text-sm hover:bg-gray-100"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  
                  ) : (
                    <div className="flex items-center justify-between">
                      <a href="#" className="text-base leading-12 text-gray-800 cursor-pointer" onClick={handleEditClick}>{planDescription}</a>
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900 mr-3"
                        onClick={handleEditClick}
                        data-html2canvas-ignore="true"
                      >
                        Edit
                      </a>
                    </div>
                  )}
                </div>

                {(plan?.opportunityPlanStep || [])?.length > 0 ? (
                  <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                      <col className="w-full" />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead className="border-b border-gray-200 text-gray-900">
                      <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                          Step
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                          Hours
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                          Value
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="py-3 pl-8 pr-0 text-right font-semibold"
                          data-html2canvas-ignore="true"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {plan?.opportunityPlanStep?.map((item) => (
                        <tr key={item.id} className="border-b border-gray-100">
                          <td className="max-w-0 px-0 py-5 align-top">
                            <div className={commonService.classNames(pdfLoading ? '': "truncate font-medium text-gray-900")}>
                              <a
                              href="#"
                              className={commonService.classNames(pdfLoading ? '': "truncate font-medium text-gray-900")}
                              onClick={() =>
                                commonService.parseNavigate(`/initiativesDetailStep/${item.id}`, navigate)
                              }
                            >
                              {item.name}
                            </a>
                            </div>
                            <div className={commonService.classNames(pdfLoading ? '': "truncate text-gray-500")}>
                              <a
                              href="#"
                              className={commonService.classNames(pdfLoading ? "": "truncate font-medium text-gray-900")}
                              onClick={() => editStep(item?.id)}
                            >
                              {item.description}
                            </a>
                            </div>
                          </td>
                          <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                            {commonService.fixDecimal(item.hours || 0)}
                          </td>
                          <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                            ${commonService.fixDecimal(item.value || 0)}
                          </td>
                          <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                            {item.status}
                          </td>
                          <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700"
                          data-html2canvas-ignore="true">
                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900 mr-3"
                              onClick={() =>
                                commonService.parseNavigate(`/initiativesDetailStep/${item.id}`, navigate)
                              }
                            >
                              View<span className="sr-only">,{item.name}</span>
                            </a>

                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900 mr-3"
                              onClick={() => editStep(item?.id)}
                            >
                              Edit<span className="sr-only">,{item.name}</span>
                            </a>
                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900 mr-3"
                              onClick={(e) => openDeleteStepModal(e, item)}
                            >
                              Delete<span className="sr-only">,{item.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                      <tr data-html2canvas-ignore="true">
                        <td
                          colSpan={5}
                          className="relative whitespace-nowrap py-5 text-sm font-medium sm:pr-0"
                        >
                          <button
                            type="button"
                            className=" w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => openStepModal()}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <button
                    type="button"
                    className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => openStepModal()}
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-900" data-html2canvas-ignore="true">
                      Create Step
                    </span>
                  </button>
                )}
              </div>

<div className="lg:col-start-3">
                {/* Activity feed */}
                <h2 className="text-sm font-semibold leading-6 text-gray-900">
                  Activity
                </h2>
                <ul className="mt-6 space-y-6">
                  {(activities || []).map((activityItem, activityItemIdx) => (
                    <li key={activityItem.id} className="relative flex gap-x-4">
                      <div
                        className={commonService.classNames(
                          activityItemIdx === (activities || []).length - 1
                            ? "h-6"
                            : "-bottom-6",
                          "absolute left-0 top-0 flex w-6 justify-center"
                        )}
                      >
                        <div className="w-px bg-gray-200" />
                      </div>
                      {activityItem.action === "comment" ? (
                        <>
                          <img
                            src={activityItem.user?.pictureUrl}
                            alt=""
                            className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                          />
                          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {activityItem.user?.username}
                                </span>{" "}
                                commented
                              </div>
                              <time
                                dateTime={activityItem.createdOn}
                                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                              >
                                {activityItem.timeAgo}
                              </time>
                            </div>
                            <p className="text-sm leading-6 text-gray-500">
                              {activityItem.comment}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {activityItem.action === "paid" ? (
                              <CheckCircleIcon
                                className="h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                              />
                            ) : (
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            )}
                          </div>
                          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                            <span className="font-medium text-gray-900">
                              {activityItem.user?.username}
                            </span>{" "}
                            {activityItem.action} the invoice.
                          </p>
                          <time
                            dateTime={activityItem.createdOn}
                            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                          >
                            {activityItem.timeAgo}
                          </time>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                <InitiativeDetailsHistory plan={plan} refresh={getOpportunityPlanActivity} />
              </div>


            </div>
          )}
        </div>
      </div>
    </>
  );
}
