import { Listbox, Transition } from '@headlessui/react'
import { FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, PaperClipIcon, XMarkIcon as XMarkIconMini, } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import commonService from '../../../app/service/commonService'
import OpportunityService from '../../../app/service/opportunityService'
import Spinner from '../../../app/spinner/spinner'

const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'I feel nothing', value: null, icon: XMarkIconMini, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
];

interface Props {
    plan: OpportunityPlanItem | undefined;
    refresh?: any;
}

export function InitiativeDetailsHistory(props: Readonly<Props>) {
    const [selected, setSelected] = useState(moods[5]);
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const addComment = (e?: any) => {
        e?.preventDefault();
        const userData = commonService.userDetail();
        const request: CreateOrUpdateOpportunityPlanActivityRequest = {
          accountId: commonService.getAccountId(),
          projectId: props?.plan?.projectId ?? '',
          opportunityPlanId: props?.plan?.id ?? '',
          userId: userData?.id,
          action: "comment",
          link: "",
          comment: comment,
          createdOn: commonService.getDate(),
          isActive: true
        }
              setLoading(true);
        OpportunityService.createOpportunityPlanActivity(request)
            .then((response: any) => {
              setLoading(false);
              commonService.showMessage(response?.data || {});
              if (response?.data?.isSuccess) {
                setComment('');
                props.refresh();
              }
            })
            .catch((e: any) => {
              toast.error(`${e?.response?.data?.message}`);
              setLoading(false);
            });
      }

    return (

        <div className="mt-6 flex gap-x-3" data-html2canvas-ignore="true">
            <img
                src={commonService.profileImage() || ''}
                alt=""
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
            <form action="#" className="relative flex-auto" onSubmit={addComment}>
                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                    <label htmlFor="comment" className="sr-only">
                        Add your comment
                    </label>
                    <textarea
                        rows={2}
                        name="comment"
                        id="comment"
                        className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Add your comment..."
                        // defaultValue={''}
                        value={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                    />
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                    <div className="flex items-center space-x-5">
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                            >
                                <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                                <span className="sr-only">Attach a file</span>
                            </button>
                        </div>
                        <div className="flex items-center">
                            <Listbox value={selected} onChange={setSelected}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="sr-only">Your mood</Listbox.Label>
                                        <div className="relative">
                                            <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                                <span className="flex items-center justify-center">
                                                    {selected.value === null ? (
                                                        <span>
                                                            <FaceSmileIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                                            <span className="sr-only">Add your mood</span>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span
                                                                className={commonService.classNames(
                                                                    selected.bgColor,
                                                                    'flex h-8 w-8 items-center justify-center rounded-full'
                                                                )}
                                                            >
                                                                <selected.icon
                                                                    className="h-5 w-5 flex-shrink-0 text-white"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                            <span className="sr-only">{selected.name}</span>
                                                        </span>
                                                    )}
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                                    {moods.map((mood) => (
                                                        <Listbox.Option
                                                            key={mood.value}
                                                            className={({ active }) =>
                                                                commonService.classNames(
                                                                    active ? 'bg-gray-100' : 'bg-white',
                                                                    'relative cursor-default select-none px-3 py-2'
                                                                )
                                                            }
                                                            value={mood}
                                                        >
                                                            <div className="flex items-center">
                                                                <div
                                                                    className={commonService.classNames(
                                                                        mood.bgColor,
                                                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                                                    )}
                                                                >
                                                                    <mood.icon
                                                                        className={commonService.classNames(mood.iconColor, 'h-5 w-5 flex-shrink-0')}
                                                                        aria-hidden="true"
                                                                    />
                                                                </div>
                                                                <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                                            </div>
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={commonService.classNames("rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                        loading ? "disabled:opacity-75": "")}
                        disabled={loading}
                    >
                        <div className="flex"><Spinner show={loading} isPrimary={true} />
                        Comment
                        </div>
                    </button>
                </div>
            </form>
        </div>
    )
}