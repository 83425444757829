import { useEffect, useRef, useState } from "react";
import { ModalWrapper } from "../../common/modalWrapper";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import AuthenticationService from "../../../app/service/authService";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";



interface Props {
    show: boolean;
    closeModal?: any;
}

const errorState: any = {
    firstname: { hasError: false, required: true },
    lastname: { hasError: false, required: true },
    email: { hasError: false, required: true },
    role: { hasError: false, required: true },
    provider: { hasError: false, required: true },
};

export default function InviteUserModal(props: Readonly<Props>) {
    const [open, setOpen] = useState(props.show);
    const [hasError, setHasError] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const cancelButtonRef = useRef(null);
    const [error, setError] = useState<any>(
        JSON.parse(JSON.stringify(errorState))
    );
    const [currentUser, setCurrentUser] = useState<User>();
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [role, setRole] = useState<string>("Reader");
    const [provider, setProvider] = useState<string>("Float FinOps");

    const [roleList, setRoleList] = useState<string[]>();
    const [providerList, setProviderList] = useState<string[]>();

    useEffect(() => {
        setOpen(props.show);
        const user = JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
        setCurrentUser(user);
        initInvitation();
    }, [props.show]);

    const initInvitation = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setRole("Reader");
        setProvider("Float FinOps");
        resetErrorState();
        const roles: string[] = ["Owner", "Contributor", "Reader"];
        setRoleList(roles);
        const providers: string[] = ["Float FinOps", "Microsoft"];
        setProviderList(providers);
    };

    const resetErrorState = () => {
        Object.keys(errorState).forEach((key) => {
            errorState[key].hasError = false;
        });
        setHasError(false);
    };

    const closeModal = (refresh?: boolean) => {
        setOpen(false);
        props.closeModal(refresh);
    };

    const onFirstnameChange = (value: string) => {
        setFirstname(value);
        checkError();
    };

    const onLastnameChange = (value: string) => {
        setLastname(value);
        checkError();
    };

    const onEmailChange = (value: string) => {
        setEmail(value);
        checkError();
    };

    const onRoleChange = (value: string) => {
        setRole(value);
        checkError();
    };

    const onProviderChange = (value: string) => {
        setProvider(value);
        checkError();
    };

    const checkError = () => {
        let hasError = false;
        const fields = ["firstname", "lastname", "email", "role", "provider"];
        
        fields.forEach((field) => {
            if (!eval(field)) {
                errorState[field].hasError = true;
                hasError = true;
            } else {
                errorState[field].hasError = false;
            }
        });
        
        setError({ ...errorState });
        setHasError(hasError);
    
        if (!hasError) {
            resetErrorState();
        }
    };

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(" ");
    }

    const inviteUser = () => {
        checkError();
        if (!hasError && email && currentUser) {
          setLoading(true);
          
          const requestBody: InviteUserRequest = {
            firstName: firstname,
            lastName: lastname,
            email: email,
            role: role,
            customerId: currentUser.accountId,
            provider: provider
          };
          AuthenticationService.inviteUser(requestBody)
            .then((response: any) => {
              commonService.showMessage(response?.data || {});
              setLoading(false);
              if (response?.data?.isSuccess) {
                closeModal();
              }
            })
            .catch((e: any) => {
              toast.error(`${e?.response?.data?.message}`);
              setLoading(false);
            });
        }
    };

    return (
        <ModalWrapper
          open={open}
          cancelButtonRef={cancelButtonRef}
          closeModal={closeModal}
        >
          <Dialog.Panel className="relative z-500 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
            <div>
              <div className="mt-3 mb-10 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-base text-center font-semibold leading-6 text-gray-900"
                >
                  Invite User
                </Dialog.Title>
                <div className="mt-2">
                  <div>
                    <form className="space-y-6">
                      <div className="mt-6 grid grid-cols-2 gap-x-6 gap-y-8 z-100">
                      <div className="col-span-1">
                          <label className="block text-sm font-medium leading-6 text-gray-900"
                          htmlFor="firstname">
                            Firstname:
                          </label>
                          <dd className="mt-2">
                              <div className="mt-1">
                                <input
                                  id="firstname"
                                  name="firstname"
                                  type="text"
                                  required
                                  value={firstname}
                                  onChange={(e) =>
                                    onFirstnameChange(e?.target?.value)
                                  }
                                  className={classNames(
                                    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                    error?.name?.hasError
                                      ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                      : ""
                                  )}
                                />
                              </div>
                          </dd>
    
                          {error?.name?.hasError && (
                            <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            Firstname is required.
                          </p>
                          )}
                        </div>

                        <div className="col-span-1">
                          <label className="block text-sm font-medium leading-6 text-gray-900"
                          htmlFor="lastname">
                            Lastname:
                          </label>
                          <dd className="mt-2">
                              <div className="mt-1">
                                <input
                                  id="lastname"
                                  name="lastname"
                                  type="text"
                                  required
                                  value={lastname}
                                  onChange={(e) =>
                                    onLastnameChange(e?.target?.value)
                                  }
                                  className={classNames(
                                    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                    error?.name?.hasError
                                      ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                      : ""
                                  )}
                                />
                              </div>
                          </dd>
    
                          {error?.name?.hasError && (
                            <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            Lastname is required.
                          </p>
                          )}
                        </div>

                        <div className="col-span-1">
                          <label className="block text-sm font-medium leading-6 text-gray-900"
                          htmlFor="email">
                            Email:
                          </label>
                          <dd className="mt-2">
                              <div className="mt-1">
                                <input
                                  id="email"
                                  name="email"
                                  type="text"
                                  required
                                  value={email}
                                  onChange={(e) =>
                                    onEmailChange(e?.target?.value)
                                  }
                                  className={classNames(
                                    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                    error?.name?.hasError
                                      ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                      : ""
                                  )}
                                />
                              </div>
                          </dd>
    
                          {error?.name?.hasError && (
                            <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            Email is required.
                          </p>
                          )}
                        </div>
    
                        <div className="col-span-1">
                          <label
                            htmlFor="role"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Role:
                          </label>
                          <div className="mt-2">
                            <select
                              id="role"
                              name="role"
                              value={role}
                              onChange={(e) => onRoleChange(e?.target?.value)}
                              className={classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              )}
                            >
                              {roleList?.map((c: string) => (
                                <option value={c} key={c}>
                                  {c}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-span-1">
                          <label
                            htmlFor="provider"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Provider:
                          </label>
                          <div className="mt-2">
                            <select
                              id="provider"
                              name="provider"
                              value={provider}
                              onChange={(e) => onProviderChange(e?.target?.value)}
                              className={classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              )}
                            >
                              {providerList?.map((c: string) => (
                                <option value={c} key={c}>
                                  {c}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="submit"
                disabled={!email || !role || !firstname || !lastname || !provider}
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={() => inviteUser()}
              >
                <Spinner show={loading} />
                Invite
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                onClick={() => closeModal()}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </ModalWrapper>
      );
}
