import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'
import commonService from '../../app/service/commonService';

interface Props {
    data :TagsSplitResult[]
}
export function ChartCart(peops: Readonly<Props>) {
    if(!peops?.data) return (<></>);
    const colors = commonService.coldColors500;
   // Group data by TagKey and TagValue, calculating the total cost per TagValue
   const groupedData: Record<string, Record<string, number>> = {};
   peops.data.forEach((item) => {
       if (!groupedData[item.tagKey]) {
           groupedData[item.tagKey] = {};
       }
       if (!groupedData[item.tagKey][item.tagValue]) {
           groupedData[item.tagKey][item.tagValue] = 0;
       }
       groupedData[item.tagKey][item.tagValue] += item.cost;
   });


   return (
       <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
           {Object.entries(groupedData).map(([tagKey, tagValueData]) => (
               <li key={tagKey} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                   <div className="flex flex-col items-center justify-between space-y-6 p-6" style={{ maxHeight: '250px' }}>
                       <PieChart width={400} height={250}>
                           <Pie
                               dataKey="Cost"
                               data={Object.entries(tagValueData).map(([tValue, cost]) => ({TagValue: tValue, Cost: cost}))}
                               outerRadius={50}
                               fill="#8884d8"
                               label={(entry) => entry.TagValue}
                           >
                               {colors.map((color, index) => (
                                   <Cell key={`cell-${index}`} fill={color} />
                               ))}
                           </Pie>
                           <Tooltip />
                           <Legend />

                       </PieChart>
                   </div>
                   <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Tag Group</dt>
                            <dd className="text-gray-700">
                                <div className="font-medium text-gray-900">{tagKey}</div>
                            </dd>
                        </div>
                        </dl>
               </li>
           ))}
       </ul>
   );
}
