import {
  Children,
  createContext,
  isValidElement,
  useContext,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const noteTypes = [
  { type: "info", title: "Info" },
  { type: "important", title: "Important" },
];

const selectedType = (type: string) => {
  return noteTypes.find((x) => x.type === type);
};

export function Note({
  children,
  type,
}: Readonly<{
  children: React.ReactNode;
  type: string;
}>) {
  return (
    <div className="my-2 border-l-4 border-blue-400 rounded-md bg-blue-50 p-2">
      <div className="flex">
        <div className="flex-shrink-0">
          {type === "info" ? (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          ) : (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-blue-800">
            {selectedType(type)?.title}
          </h3>
          <div className="text-sm text-blue-700">
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Row({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <div className="grid grid-cols-1 items-start gap-x-16 gap-y-10 xl:max-w-none xl:grid-cols-2">
      {children}
    </div>
  );
}

export function Col({
  children,
  sticky = false,
}: Readonly<{
  children: React.ReactNode;
  sticky?: boolean;
}>) {
  return (
    <div
      className={clsx(
        "[&>:first-child]:mt-0 [&>:last-child]:mb-0",
        sticky && "xl:sticky xl:top-24"
      )}
    >
      {children}
    </div>
  );
}

export function Properties({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <div className="my-6">
      <ul className="m-0 max-w-[calc(theme(maxWidth.lg)-theme(spacing.8))] list-none divide-y divide-zinc-900/5 p-0 dark:divide-white/5">
        {children}
      </ul>
    </div>
  );
}

export function Property({
  name,
  children,
  type,
}: Readonly<{
  name: string;
  children: React.ReactNode;
  type?: string;
}>) {
  return (
    <li className="m-0 px-0 py-4 first:pt-0 last:pb-0">
      <dl className="m-0 flex flex-wrap items-center gap-x-3 gap-y-2">
        <dt className="sr-only">Name</dt>
        <dd>
          <code>{name}</code>
        </dd>
        {type && (
          <>
            <dt className="sr-only">Type</dt>
            <dd className="font-mono text-xs text-zinc-400 dark:text-zinc-500">
              {type}
            </dd>
          </>
        )}
        <dt className="sr-only">Description</dt>
        <dd className="w-full flex-none [&>:first-child]:mt-0 [&>:last-child]:mb-0">
          {children}
        </dd>
      </dl>
    </li>
  );
}

function ClipboardIcon(props: Readonly<React.ComponentPropsWithoutRef<"svg">>) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path
        strokeWidth="0"
        d="M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z"
      />
      <path
        fill="none"
        strokeLinejoin="round"
        d="M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1"
      />
    </svg>
  );
}

function CopyButton({ code }: Readonly<{ code: string }>) {
  let [copyCount, setCopyCount] = useState(0);
  let copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      let timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      type="button"
      className={clsx(
        "group/button absolute right-4 top-3.5 overflow-hidden rounded-full py-1 pl-2 pr-3 text-xs font-medium opacity-0 backdrop-blur transition focus:opacity-100 group-hover:opacity-100",
        copied
          ? "bg-emerald-400/10 ring-1 ring-inset ring-emerald-400/20"
          : "bg-white/5 hover:bg-white/7.5 dark:bg-white/2.5 dark:hover:bg-white/5"
      )}
      onClick={() => {
        window.navigator.clipboard.writeText(code).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      <span
        aria-hidden={copied}
        className={clsx(
          "pointer-events-none flex items-center gap-0.5 text-zinc-400 transition duration-300",
          copied && "-translate-y-1.5 opacity-0"
        )}
      >
        <ClipboardIcon className="h-5 w-5 fill-zinc-500/20 stroke-zinc-500 transition-colors group-hover/button:stroke-zinc-400" />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={clsx(
          "pointer-events-none absolute inset-0 flex items-center justify-center text-emerald-400 transition duration-300",
          !copied && "translate-y-1.5 opacity-0"
        )}
      >
        Copied!
      </span>
    </button>
  );
}

function CodePanelHeader({
  tag,
  label,
}: Readonly<{ tag?: string; label?: string }>) {
  if (!tag && !label) {
    return null;
  }

  return (
    <div className="flex h-9 items-center gap-2 border-y border-b-white/7.5 border-t-transparent bg-white/2.5 bg-zinc-900 px-4 dark:border-b-white/5 dark:bg-white/1">
      {tag && (
        <div className="dark flex">
          <Tag variant="small">{tag}</Tag>
        </div>
      )}
      {tag && label && (
        <span className="h-0.5 w-0.5 rounded-full bg-zinc-500" />
      )}
      {label && (
        <span className="font-mono text-xs text-zinc-400">{label}</span>
      )}
    </div>
  );
}

function CodePanel({
  children,
  tag,
  label,
  code,
}: Readonly<{
  children: React.ReactNode;
  tag?: string;
  label?: string;
  code?: string;
}>) {
  let child = Children.only(children);

  if (isValidElement(child)) {
    tag = child.props.tag ?? tag;
    label = child.props.label ?? label;
    code = child.props.code ?? code;
  }

  if (!code) {
    throw new Error(
      "`CodePanel` requires a `code` prop, or a child with a `code` prop."
    );
  }

  return (
    <div className="group dark:bg-white/2.5">
      <CodePanelHeader tag={tag} label={label} />
      <div className="relative">
        <pre className="overflow-x-auto p-4 text-xs text-emerald-400">
          {children}
        </pre>
        <CopyButton code={code} />
      </div>
    </div>
  );
}

function CodeGroupHeader({
  title,
  children,
}: Readonly<{
  title: string;
  children: React.ReactNode;
}>) {
  if (!title) {
    return null;
  }

  return (
    <div className="flex min-h-[calc(theme(spacing.12)+1px)] flex-wrap items-start gap-x-4 border-b border-zinc-700 bg-zinc-800 px-4 dark:border-zinc-800 dark:bg-transparent">
      {title && (
        <h3 className="mr-auto pt-4 text-xs font-semibold border-emerald-500 text-emerald-400">
          {title}
        </h3>
      )}
    </div>
  );
}

function CodeGroupPanels({
  children,
  ...props
}: Readonly<React.ComponentPropsWithoutRef<typeof CodePanel>>) {
  return <CodePanel {...props}>{children}</CodePanel>;
}

const CodeGroupContext = createContext(false);

export function CodeGroup({
  children,
  title,
  ...props
}: Readonly<
  React.ComponentPropsWithoutRef<typeof CodeGroupPanels> & { title: string }
>) {
  let containerClassName =
    "my-2 overflow-hidden rounded-2xl bg-zinc-900 shadow-md dark:ring-1 dark:ring-white/10";
  let header = <CodeGroupHeader title={title}>{children}</CodeGroupHeader>;
  let panels = <CodeGroupPanels {...props}>{children}</CodeGroupPanels>;

  return (
    <CodeGroupContext.Provider value={true}>
      <div className={containerClassName}>
        <div className="not-prose">
          {header}
          {panels}
        </div>
      </div>
    </CodeGroupContext.Provider>
  );
}

export function Code({
  children,
  ...props
}: Readonly<React.ComponentPropsWithoutRef<"code">>) {
  let isGrouped = useContext(CodeGroupContext);

  if (isGrouped) {
    if (typeof children !== "string") {
      throw new Error(
        "`Code` children must be a string when nested inside a `CodeGroup`."
      );
    }
    return <code {...props} dangerouslySetInnerHTML={{ __html: children }} />;
  }

  return <code {...props}>{children}</code>;
}

export function Pre({
  children,
  ...props
}: Readonly<React.ComponentPropsWithoutRef<typeof CodeGroup>>) {
  let isGrouped = useContext(CodeGroupContext);

  if (isGrouped) {
    return children;
  }

  return <CodeGroup {...props}>{children}</CodeGroup>;
}

const variantStyles = {
  small: "",
  medium: "rounded-lg px-1.5 ring-1 ring-inset",
};

const colorStyles = {
  emerald: {
    small: "text-emerald-500 dark:text-emerald-400",
    medium:
      "ring-emerald-300 dark:ring-emerald-400/30 bg-emerald-400/10 text-emerald-500 dark:text-emerald-400",
  },
  sky: {
    small: "text-sky-500",
    medium:
      "ring-sky-300 bg-sky-400/10 text-sky-500 dark:ring-sky-400/30 dark:bg-sky-400/10 dark:text-sky-400",
  },
  amber: {
    small: "text-amber-500",
    medium:
      "ring-amber-300 bg-amber-400/10 text-amber-500 dark:ring-amber-400/30 dark:bg-amber-400/10 dark:text-amber-400",
  },
  rose: {
    small: "text-red-500 dark:text-rose-500",
    medium:
      "ring-rose-200 bg-rose-50 text-red-500 dark:ring-rose-500/20 dark:bg-rose-400/10 dark:text-rose-400",
  },
  zinc: {
    small: "text-zinc-400 dark:text-zinc-500",
    medium:
      "ring-zinc-200 bg-zinc-50 text-zinc-500 dark:ring-zinc-500/20 dark:bg-zinc-400/10 dark:text-zinc-400",
  },
};

const valueColorMap = {
  GET: "emerald",
  POST: "sky",
  PUT: "amber",
  DELETE: "rose",
} as Record<string, keyof typeof colorStyles>;

export function Tag({
  children,
  variant = "medium",
  color = valueColorMap[children] ?? "emerald",
}: Readonly<{
  children: keyof typeof valueColorMap & (string | {});
  variant?: keyof typeof variantStyles;
  color?: keyof typeof colorStyles;
}>) {
  return (
    <span
      className={clsx(
        "font-mono text-[0.625rem] font-semibold leading-6",
        variantStyles[variant],
        colorStyles[color][variant]
      )}
    >
      {children}
    </span>
  );
}

export function SelectionDataList({
  title,
  value,
  hideBackground,
}: Readonly<{
  title: string;
  value: string;
  hideBackground?: boolean;
}>) {
  if (!title) {
    return null;
  }
  return (
    <div>
      <p className="text-sm">
        <strong className="text-gray-800">{title}</strong> ={" "}
        <code
          className={clsx(
            "text-xs text-gray-800 font-thin p-1",
            !hideBackground && "rounded-md border border-gray-300 bg-gray-100"
          )}
        >
          {value}
        </code>
      </p>
    </div>
  );
}

export function CodeText({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <code className="text-xs text-gray-800 font-thin p-1 rounded-md border border-gray-300 bg-gray-100">
      {children}
    </code>
  );
}
