import { Dialog } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  filterData: any;
  refreshDetails: (filterData?: any) => void;
  resourceData: any[];
  clearFilters: () => void;
  filterMaxData: any;
}

export default function FilterModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const cancelButtonRef = useRef(null);

  const [filterData, setFilterData] = useState<any>(props?.filterData || {});
  const [filterMaxData, setFilterMaxData] = useState<any>(
    props?.filterMaxData || {}
  );

  const [locationList, setLocationList] = useState<string[]>([]);
  const [sizeList, setSizeList] = useState<string[]>([]);
  const [osList, setOsList] = useState<string[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);
  const errorMsg = "Min value cannot be greater than the Max value.";

  const allText = "All";

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      initFilters();
    }
  }, [props.show]);

  useEffect(() => {
    if(isError(
      filterData.percentageFrom,
      filterData.percentageTo
    ) ||
    isError(
      filterData.recommendationsFromCount,
      filterData.recommendationsToCount
    ) ||
    isError(
      filterData.errosFromCount,
      filterData.errosToCount
    ) || 
    isError(
      filterData.hardwareCurrentFrom,
      filterData.hardwareCurrentTo
    ) ||
    isError(
      filterData.reservationSavingsFrom,
      filterData.reservationSavingsTo
    ) ||
    isError(
      filterData.reservationBreakEvenFrom,
      filterData.reservationBreakEvenTo
    ) ||
    isError(
      filterData.licensingCurrentFrom,
      filterData.licensingCurrentTo
    ) || 
    isError(
      filterData.licensingSavingsFrom,
      filterData.licensingSavingsTo
    ) || 
    isError(
      filterData.licensingBreakEvenFrom,
      filterData.licensingBreakEvenTo
    ) ||
    isError(
      filterData.diskSizeFrom,
      filterData.diskSizeTo
    )) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [filterData]);

  const initFilters = () => {
    const filterLocationList: string[] = [];
    const filterSizeList: string[] = [];
    const filterOsList: string[] = [];
    (props?.resourceData || []).forEach((x: any) => {
      if (filterLocationList.indexOf(x?.location) === -1) {
        filterLocationList.push(x?.location);
      }
      const size = commonService.sizePipe(x.size || "");
      if (filterSizeList.indexOf(size) === -1) {
        filterSizeList.push(size);
      }
      if (filterOsList.indexOf(x?.operativeSystem) === -1) {
        filterOsList.push(x?.operativeSystem);
      }
    });
    setLocationList(filterLocationList);
    setSizeList(filterSizeList);
    setOsList(filterOsList);
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onInputChange = (key: string, value: any) => {
    const data = JSON.parse(JSON.stringify(filterData));
    data[key] = value;
    setFilterData(data);
  };

  const filter = () => {
    props.refreshDetails(filterData);
    closeModal();
  };

  const clearFilters = () => {
    props.clearFilters();
    closeModal();
  };

  const isError = (min: string, max: string) => {
    return parseFloat(min || '0') > parseFloat(max || '0');
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative z-500 transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
                  <div>
                    <div className="mt-3 mb-10 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base text-center font-semibold leading-6 text-gray-900"
                      >
                        Filter
                      </Dialog.Title>
                      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Name
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div>
                              <div>
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Name
                                </label>
                                <div className="mb-2">
                                  <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    value={filterData.name}
                                    onChange={(e) =>
                                      onInputChange("name", e?.target?.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="location"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Location
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="location"
                                    name="location"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.location}
                                    onChange={(e) => {
                                      onInputChange(
                                        "location",
                                        e?.target?.value
                                      );
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {locationList.map((location: string) => (
                                      <option value={location} key={location}>
                                        {location}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="size"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Size
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="size"
                                    name="size"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.size}
                                    onChange={(e) => {
                                      onInputChange("size", e?.target?.value);
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {sizeList.map((size: string) => (
                                      <option value={size} key={size}>
                                        {size}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="os"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  OS
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="os"
                                    name="os"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.os}
                                    onChange={(e) => {
                                      onInputChange("os", e?.target?.value);
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {osList.map((os: string) => (
                                      <option value={os} key={os}>
                                        {os}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Run Time Hours
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="percentageFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Percentage (Min):{" "}
                                      {filterData.percentageFrom}
                                    </label>
                                    <div>
                                      <input
                                        type="range"
                                        name="percentageFrom"
                                        id="percentageFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.percentageMax || 0}
                                        value={filterData.percentageFrom}
                                        onChange={(e) =>
                                          onInputChange(
                                            "percentageFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="percentageTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Percentage (Max):{" "}
                                      {filterData.percentageTo}
                                    </label>
                                    <div>
                                      <input
                                        type="range"
                                        name="percentageTo"
                                        id="percentageTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.percentageMax || 0}
                                        value={filterData.percentageTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "percentageTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.percentageFrom,
                                    filterData.percentageTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="recommendationsFromCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Recommendations (Min):{" "}
                                      {filterData.recommendationsFromCount}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="recommendationsFromCount"
                                        id="recommendationsFromCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.recommendationsCountMax ||
                                          0
                                        }
                                        value={
                                          filterData.recommendationsFromCount
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "recommendationsFromCount",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="recommendationsToCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Recommendations (Max):{" "}
                                      {filterData.recommendationsToCount}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="recommendationsToCount"
                                        id="recommendationsToCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.recommendationsCountMax ||
                                          0
                                        }
                                        value={
                                          filterData.recommendationsToCount
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "recommendationsToCount",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.recommendationsFromCount,
                                    filterData.recommendationsToCount
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="errosFromCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Errors (Min): {filterData.errosFromCount}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="errosFromCount"
                                        id="errosFromCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.errosCountMax || 0}
                                        value={filterData.errosFromCount}
                                        onChange={(e) =>
                                          onInputChange(
                                            "errosFromCount",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="errosToCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Errors (Max): {filterData.errosToCount}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="errosToCount"
                                        id="errosToCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.errosCountMax || 0}
                                        value={filterData.errosToCount}
                                        onChange={(e) =>
                                          onInputChange(
                                            "errosToCount",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.errosFromCount,
                                    filterData.errosToCount
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Hardware
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="hardwareCurrentFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Min):{" "}
                                      {filterData.hardwareCurrentFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="hardwareCurrentFrom"
                                        id="hardwareCurrentFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.hardwareCurrentMax || 0
                                        }
                                        value={filterData.hardwareCurrentFrom}
                                        onChange={(e) =>
                                          onInputChange(
                                            "hardwareCurrentFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="hardwareCurrentTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Max):{" "}
                                      {filterData.hardwareCurrentTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="hardwareCurrentTo"
                                        id="hardwareCurrentTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.hardwareCurrentMax || 0
                                        }
                                        value={filterData.hardwareCurrentTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "hardwareCurrentTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.hardwareCurrentFrom,
                                    filterData.hardwareCurrentTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationSavingsFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation Savings (Min):{" "}
                                      {filterData.reservationSavingsFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationSavingsFrom"
                                        id="reservationSavingsFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.reservationSavingsMax ||
                                          0
                                        }
                                        value={
                                          filterData.reservationSavingsFrom
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationSavingsFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationSavingsTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation Savings (Max):{" "}
                                      {filterData.reservationSavingsTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationSavingsTo"
                                        id="reservationSavingsTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.reservationSavingsMax ||
                                          0
                                        }
                                        value={filterData.reservationSavingsTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationSavingsTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.reservationSavingsFrom,
                                    filterData.reservationSavingsTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationBreakEvenFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation BreakEven (Min):{" "}
                                      {filterData.reservationBreakEvenFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationBreakEvenFrom"
                                        id="reservationBreakEvenFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.reservationBreakEvenMax ||
                                          0
                                        }
                                        value={
                                          filterData.reservationBreakEvenFrom
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationBreakEvenFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationBreakEvenTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation BreakEven (Max):{" "}
                                      {filterData.reservationBreakEvenTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationBreakEvenTo"
                                        id="reservationBreakEvenTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.reservationBreakEvenMax ||
                                          0
                                        }
                                        value={
                                          filterData.reservationBreakEvenTo
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationBreakEvenTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.reservationBreakEvenFrom,
                                    filterData.reservationBreakEvenTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Licensing
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingCurrentFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Min):{" "}
                                      {filterData.licensingCurrentFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingCurrentFrom"
                                        id="licensingCurrentFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingCurrentMax ||
                                          0
                                        }
                                        value={filterData.licensingCurrentFrom}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingCurrentFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingCurrentTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Max):{" "}
                                      {filterData.licensingCurrentTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingCurrentTo"
                                        id="licensingCurrentTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingCurrentMax ||
                                          0
                                        }
                                        value={filterData.licensingCurrentTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingCurrentTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingCurrentFrom,
                                    filterData.licensingCurrentTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingSavingsFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase Savings (Min):{" "}
                                      {filterData.licensingSavingsFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingSavingsFrom"
                                        id="licensingSavingsFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingSavingsMax ||
                                          0
                                        }
                                        value={filterData.licensingSavingsFrom}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingSavingsFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingSavingsTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase Savings (Max):{" "}
                                      {filterData.licensingSavingsTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingSavingsTo"
                                        id="licensingSavingsTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingSavingsMax ||
                                          0
                                        }
                                        value={filterData.licensingSavingsTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingSavingsTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingSavingsFrom,
                                    filterData.licensingSavingsTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingBreakEvenFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase BreakEven (Min):{" "}
                                      {filterData.licensingBreakEvenFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingBreakEvenFrom"
                                        id="licensingBreakEvenFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingBreakEvenMax ||
                                          0
                                        }
                                        value={
                                          filterData.licensingBreakEvenFrom
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingBreakEvenFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingBreakEvenTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase BreakEven (Max):{" "}
                                      {filterData.licensingBreakEvenTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingBreakEvenTo"
                                        id="licensingBreakEvenTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterMaxData?.licensingBreakEvenMax ||
                                          0
                                        }
                                        value={filterData.licensingBreakEvenTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingBreakEvenTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingBreakEvenFrom,
                                    filterData.licensingBreakEvenTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Disk
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <label
                                  htmlFor="diskType"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Type
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="diskType"
                                    name="diskType"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.diskType}
                                    onChange={(e) => {
                                      onInputChange(
                                        "diskType",
                                        e?.target?.value
                                      );
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    <option value="os" key="os">
                                      OS Disk
                                    </option>
                                    <option value="data" key="data">
                                      Data Disk
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="diskSizeFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Size (Min): {filterData.diskSizeFrom}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="diskSizeFrom"
                                        id="diskSizeFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.diskSizeMax || 0}
                                        value={filterData.diskSizeFrom}
                                        onChange={(e) =>
                                          onInputChange(
                                            "diskSizeFrom",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="diskSizeTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Size (Max): {filterData.diskSizeTo}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="diskSizeTo"
                                        id="diskSizeTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterMaxData?.diskSizeMax || 0}
                                        value={filterData.diskSizeTo}
                                        onChange={(e) =>
                                          onInputChange(
                                            "diskSizeTo",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.diskSizeFrom,
                                    filterData.diskSizeTo
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                    {!hasError && <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3"
                      onClick={() => filter()}
                    >
                      Filter
                    </button>}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                      onClick={() => clearFilters()}
                    >
                      Clear All Filters
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
    </ModalWrapper>
  );
}
