interface MapIconWithProviderType {
    [key: string]: {
        [key: string]: {
            [key: string]: string;
        };
    };
}

const azureBaseUrl: string = "https://ffglasshostingprod.blob.core.windows.net/azure-icons/";

const mapWithProvider: MapIconWithProviderType = {
    "azure": {
        "microsoft.apimanagement": {
            "service": `${azureBaseUrl}API-Management-Services.svg`
        },
        "microsoft.compute": {
            "virtualmachines": `${azureBaseUrl}Virtual-Machine.svg`,
            "virtualmachinescalesets": `${azureBaseUrl}VM-Scale-Sets.svg`,
        },
        "microsoft.sql":{
            "servers": `${azureBaseUrl}SQL-Server.svg`
        },
        "microsoft.storage":{
            "storageaccounts": `${azureBaseUrl}Storage-Accounts.svg`
        },
        "microsoft.keyvault": {
            "vaults": `${azureBaseUrl}Key-Vaults.svg`
        },
        "microsoft.web":{
            "serverfarms": `${azureBaseUrl}Server-Farm.svg`,
            "sites": `${azureBaseUrl}Website-Power.svg`
        },
        "microsoft.documentdb":{
            "databaseaccounts": `${azureBaseUrl}Production-Ready-Database.svg`
        },
        "microsoft.dbformysql":{
            "servers": `${azureBaseUrl}Azure-Database-MySQL-Server.svg`
        },
        "microsoft.databricks":{
            "workspaces": `${azureBaseUrl}Workspaces.svg`
        },
        "microsoft.machinelearningservices":{
            "workspaces": `${azureBaseUrl}Workspaces.svg`
        },
        "microsoft.visualstudio":{
            "organizations": `${azureBaseUrl}Groups.svg`
        },
        "microsoft.recoveryservices":{
            "vaults": `${azureBaseUrl}Recovery-Services-Vaults.svg`
        }
    },
    "aws": {}
}

const IconSelectorWithProvider = (csp: string, provider: string, resourceType: string): string => {
    const defaultIconPath = `${azureBaseUrl}Search.svg`;
    const iconPath = mapWithProvider[csp.toLocaleLowerCase()]?.[provider.toLocaleLowerCase()]?.[resourceType.toLocaleLowerCase()] || defaultIconPath;
    return iconPath;
};

interface MapIconWithCategoryType {
    [key: string]: {
        [key: string]: string;
    };
}

const mapWithCategory: MapIconWithCategoryType = {
    "azure": {
        "azure dns": `${azureBaseUrl}DNS-Zones.svg`,
        "application gateway": `${azureBaseUrl}Application-Gateways.svg`,
        "azure database for mysql": `${azureBaseUrl}Azure-Database-MySQL-Server.svg`,
        "azure site recovery": `${azureBaseUrl}Recovery-Services-Vaults.svg`,
        "event hubs": `${azureBaseUrl}Event-Hubs.svg`,
        "security center": `${azureBaseUrl}Security-Center.svg`,
        "storage": `${azureBaseUrl}Storage-Accounts.svg`,
        "virtual network": `${azureBaseUrl}Virtual-Networks-(Classic).svg`,
        "api management": `${azureBaseUrl}API-Management-Services.svg`,
        "azure app service": `${azureBaseUrl}App-Services.svg`,
        "cloud services": `${azureBaseUrl}Cloud-Services-(Classic).svg`,
        "container registry": `${azureBaseUrl}Container-Registries.svg`,
        "event grid": `${azureBaseUrl}Event-Grid-Domains.svg`,
        "key vault": `${azureBaseUrl}Key-Vaults.svg`,
        "load balancer": `${azureBaseUrl}Load-Balancers.svg`,
        "log analytics": `${azureBaseUrl}Log-Analytics-Workspaces.svg`,
        "sql database": `${azureBaseUrl}SQL-Database.svg`,
        "virtual machines": `${azureBaseUrl}Virtual-Machine.svg`,
        "backup": `${azureBaseUrl}Recovery-Services-Vaults.svg`,
        "advanced data security": `${azureBaseUrl}Security-Center.svg`,
        "azure devops": `${azureBaseUrl}Azure-DevOps.svg`,
        "azure cosmos db": `${azureBaseUrl}Azure-Cosmos-DB.svg`,
        "azure synapse analytics": `${azureBaseUrl}Azure-Synapse-Analytics.svg`,
        "vpn gateway": `${azureBaseUrl}Virtual-Network-Gateways.svg`,
        "sentinel": `${azureBaseUrl}Azure-Sentinel.svg`,
        "azure bastion": `${azureBaseUrl}azure-bastion.svg`,
        "azure databricks": `${azureBaseUrl}databricks.svg`,
        "bandwidth": `${azureBaseUrl}Virtual-Networks.svg`,
        "advanced threat protection": `${azureBaseUrl}Security-Center.svg`,
        "azure purview": `${azureBaseUrl}Purview.svg`,
    },
    "aws": {}
}

const IconSelectorWithCategory = (csp: string, category: string): string => {
    const defaultIconPath = `${azureBaseUrl}Search.svg`;
    const iconPath = mapWithCategory[csp.toLocaleLowerCase()]?.[category.toLocaleLowerCase()] || defaultIconPath;
    return iconPath;
};

const iconService = {
    IconSelectorWithProvider,
    IconSelectorWithCategory
}

export default iconService;
