import Pulse from "../pulse/pulse";

export default function AppRedirect() {
  const loading = true;

  return (
    <div className="py-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {loading && <Pulse show={loading} />}
          </div>
        </div>
      </div>
  );
}
