import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import commonService from "./service/commonService";
import Spinner from "./spinner/spinner";
import { useState } from "react";
import backgroundImg from '../assets/logo/AdobeStock_616564080.jpeg';
import { loginRequest } from "../authConfig";

const PageLayout = (props: any) => {
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    const { instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const dbkProfile = commonService.dbkProfile;
    const handleLogin = async () => {
        setLoading(true);
        await instance.loginRedirect({
            scopes: loginRequest.scopes
        }).then(() => setLoading(false)).catch(() => setLoading(false));
    };

    return (
      <>
        <UnauthenticatedTemplate>
          <main>
            <div className="flex min-h-full flex-1 h-screen">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="flex justify-center">
                    <img
                        className="h-16 w-auto"
                        src={dbkProfile.imgUrl}
                        alt={dbkProfile.name}
                    />
                    </div>
                    <h2 className="mt-8 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                    </h2>
                    <p className="mt-2 text-sm text-center leading-6 text-gray-500">
                    Not a member?{" "}
                    <a
                        href="#"
                        // onClick={() => navigateURL("/register")}
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                        Start a 14 day free trial
                    </a>
                    </p>

                    <div className="mt-10">
                    <div>
                        <div>
                        <button
                            type="submit"
                            onClick={handleLogin}
                            disabled={loading}
                            className={commonService.classNames(
                            "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 mt-5",
                            loading ? "disabled:opacity-75" : ""
                            )}
                        >
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                            >
                            <path d="M1.5 3.5h10v9h-10v-9zm11 0h10v9h-10v-9zm0 10h10v9h-10v-9zm-11 0h10v9h-10v-9z" />
                            </svg>
                            <Spinner show={loading} />
                            Sign in with Microsoft
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={backgroundImg}
                    alt=""
                />
                </div>
            </div>
          </main>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          {/* <Header /> */}
          <>{props.children}</>
          {/* <Footer /> */}
        </AuthenticatedTemplate>
      </>
    );
  };
  
  export default PageLayout;