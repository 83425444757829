import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  isEdit?: boolean;
  budget: TenantBudgetItem | any;
  subscriptionList: SubscriptionItem[];
  projects: ProjectItem[];
}

const errorState: any = {
  budget: { hasError: false, required: true },
  subscriptionId: { hasError: false, required: true },
  projectId: { hasError: false, required: true },
};

export default function BudgetModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    init();
    checkError();
  }, [props.show]);

  const init = () => {
    setBudget(initBudget());
  };

  const initBudget = (): TenantBudgetItem => {
    if (props.isEdit) {
      return JSON.parse(JSON.stringify(props.budget ?? {})) || {};
    }
    return {
      id: "",
      customerId: commonService.getAccountId(),
      subscriptionId: props?.subscriptionList[0]?.subscriptionId || "",
      projectId: props?.projects[0]?.id || "",
      budget: 0,
      periodStart: "",
      periodEnd: "",
    };
  };

  const [budget, setBudget] = useState<TenantBudgetItem | any>(initBudget());

  useEffect(() => {
    checkError();
  }, [budget]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const createBudget = () => {
    onCheckError();
    checkError();
    if (!hasError) {
      const body: CreateOrUpdateTenantBudgetRequest = {
        id: "",
        customerId: budget.customerId,
        subscriptionId: budget.subscriptionId,
        projectId: budget.projectId,
        budget: budget.budget || 0,
      };
      if (props?.isEdit) {
        body.id = budget?.id;
      } else {
        delete body.id;
      }
      setLoading(true);
      if (props?.isEdit) {
        CloudResourceService.updateTenantBudget(body)
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      } else {
        CloudResourceService.createTenantBudget(body)
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      }
    }
  };

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(budget));
    for (const key of Object.keys(errorState) || []) {
      onInputChange(key, model[key] ?? "");
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = value?.toString()?.toLowerCase()?.length === 0;
    const model = JSON.parse(JSON.stringify(budget));
    model[fieldName] = value;
    setBudget(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (
        errorState[key]?.required &&
        budget[key]?.toString()?.toLowerCase()?.length === 0
      ) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {props?.isEdit ? `Edit Budget` : "Add Budget"}
            </Dialog.Title>
            <div className="mt-2">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="budget"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Budget
                  </label>
                  <div className="mt-2">
                    <input
                      id="budget"
                      name="budget"
                      type="number"
                      required
                      value={budget.budget}
                      onChange={(e) =>
                        onInputChange("budget", e?.target?.value)
                      }
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        error?.budget?.hasError
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                    />
                  </div>
                  {error?.budget?.hasError && (
                    <p className="mt-2 text-sm text-red-600" id="order-error">
                      Budget is required.
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="subscriptionId"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Subscription
                  </label>
                  <div className="mt-1 mb-1">
                    <select
                      id="subscriptionId"
                      name="subscriptionId"
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        error?.subscriptionId?.hasError
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                      value={budget.subscriptionId}
                      required
                      onChange={(e) =>
                        onInputChange("subscriptionId", e?.target?.value)
                      }
                    >
                      {(props?.subscriptionList || []).map(
                        (subscription: SubscriptionItem) => (
                          <option
                            key={subscription?.subscriptionId}
                            value={subscription?.subscriptionId}
                          >
                            {subscription?.subcriptionName}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  {error?.subscriptionId?.hasError && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="subscriptionId-error"
                    >
                      Subscription is required.
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="projectId"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project
                  </label>
                  <div className="mt-1 mb-1">
                    <select
                      id="projectId"
                      name="projectId"
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        error?.projectId?.hasError
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                      value={budget.projectId}
                      required
                      onChange={(e) =>
                        onInputChange("projectId", e?.target?.value)
                      }
                    >
                      {(props?.projects || []).map((project: ProjectItem) => (
                        <option key={project?.id} value={project?.id}>
                          {project?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error?.projectId?.hasError && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="projectId-error"
                    >
                      Project is required.
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createBudget()}
          >
            <Spinner show={loading} />
            {props?.isEdit ? "Update" : "Create"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
