import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import {
  Bar,
  BarChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import commonService from "../../../app/service/commonService";
interface Props {
  storageAccount: any;
  show: boolean;
  closeModal?: any;
}

export default function StorageCostBreakDownModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const cancelButtonRef = useRef(null);
  const [pieChartData, setPieChartData] = useState<
    Array<{ name: string; value: number }>
  >([]);

  const [barChartData, setBarChartData] = useState<
    Array<{ date: string; data: any }>
  >([]);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    const invoiceData = sortByDate(props?.storageAccount?.invoiceInfo || []);
    const pieData: Array<{ name: string; value: number }> = [];
    const barData: Array<{ date: string; data: any; parseDate: string }> = [];
    invoiceData.forEach((x: any) => {
      const i = pieData.findIndex((p) => p.name === x.meterName);
      const cost = parseFloat(commonService.fixDecimal(x.cost || 0));
      if (i === -1) {
        pieData.push({ name: x.meterName, value: cost });
      } else {
        pieData[i].value = parseFloat(
          commonService.fixDecimal(pieData[i].value + cost)
        );
      }

      const date = x.date?.split("T")[0] || "";
      const j = barData.findIndex((b) => b.date === date);
      if (j === -1) {
        const barChartCostData: any = {};
        barChartCostData[x.meterName] = cost;
        barData.push({
          date: date,
          data: barChartCostData,
          parseDate: new Date(date).getDate()?.toString(),
        });
      } else {
        if (barData[j].data?.hasOwnProperty(x.meterName)) {
          barData[j].data[x.meterName] = parseFloat(commonService.fixDecimal(barData[j].data[x.meterName] + cost));
        } else {
          barData[j].data[x.meterName] = cost;
        }
      }
    });
    setBarChartData(barData);
    setPieChartData(pieData);
  };

  const sortByDate = (data: any[]) => {
    const orderedData: any[] = (data || []).sort((a, b) => {
      const aDate = new Date(a.date + "Z");
      const bDate = new Date(b.date + "Z");
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0;
      }
    });
    return orderedData || [];
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const barChartTooltipLableFormatter = (_: string, b: any) => {
    return b[0]?.payload?.date || "";
  };

  const formatCurrency = (value: any) => {
    return `$${value}`;
  }

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Storage Cost Breakdown
            </Dialog.Title>

            <div className="mt-2 grid grid-cols-2 gap-6">
              <div className="h-full overflow-y-auto flex-auto content-center">
                {(pieChartData || [])?.length > 0 ? (
                  <table className="mt-2 w-full whitespace-nowrap text-left text-sm leading-6">
                    <thead className="border-b border-gray-200 text-gray-900">
                      <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(pieChartData || [])?.map((item, index) => (
                        <tr
                          key={item.name}
                          className="border-b border-gray-100"
                        >
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-900">
                            <div className="flex items-center">
                              <div
                                className="w-4 h-4 border-1 mr-2"
                                style={{
                                  backgroundColor:
                                    commonService.coldColors500[
                                      index % commonService.coldColors500.length
                                    ],
                                }}
                              ></div>
                              {item.name}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            ${item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <button
                    type="button"
                    className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                      No data found.
                    </span>
                  </button>
                )}
              </div>
              {pieChartData?.length > 0 && (
                <div
                  className="flex-auto"
                  style={{ width: "100%", height: "400px" }}
                >
                  <ResponsiveContainer>
                    <PieChart width={250} height={250}>
                      <Pie
                        data={pieChartData || []}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {(pieChartData || []).map((entry, index) => (
                          <Cell
                            key={`cell-${entry.name}`}
                            fill={
                              commonService.coldColors500[
                                index % commonService.coldColors500.length
                              ]
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip formatter={formatCurrency} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>

            {barChartData?.length > 0 && (
              <div className="mt-2">
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Daily Invoice Breakdown
                  </span>
                </div>
                <div
                  className="flex shrink-0 items-center gap-x-4"
                  style={{ width: "100%", height: "250px" }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={2400}
                      height={250}
                      data={barChartData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <Tooltip labelFormatter={barChartTooltipLableFormatter} formatter={formatCurrency} />
                      {/*     <Legend />  */}
                      <YAxis tickFormatter={formatCurrency} />
                      <XAxis dataKey={"parseDate"} />

                      {Object.entries(barChartData[0]?.data).map(
                        (type, typeIndex) => {
                          const dataKey = `data['${type[0]}']`;
                          const color =
                            commonService.coldColors500[
                              typeIndex % commonService.coldColors500.length
                            ]; // Cycle through colors
                          return (
                            <Bar
                              key={dataKey}
                              dataKey={dataKey}
                              name={type[0].toString()}
                              stackId="a"
                              fill={color}
                            />
                          );
                        }
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3 sm:grid-cols-2">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
